import * as _ from 'lodash'
export const name = 'AnimationAspect'

export const defaultModel = {
    animationManager: null
}

export const functionLibrary = {
    defineAnimations: (animationDefs, animationManager) => animationManager && animationManager.setDefinitions(animationDefs),
    getAnimationMaxTravel: (animationManager, animationName, compMeasure, viewportHeight, siteHeight) => {
        const getter = _.get(animationManager, ['animationProperties', animationName, 'getMaxTravel'], _.noop)
        return getter(compMeasure, viewportHeight, siteHeight) || viewportHeight
    },
    getTransitionDefaultDuration: (animationManager, transitionName) => _.get(animationManager, ['animationProperties', transitionName, 'defaultDuration'], 0),
    getElementByPath(path, windowObject) {
        if (!windowObject) {
            return null
        }
        const query = path.join('')
        return windowObject.document.getElementById(query) || windowObject.document.querySelector(query)
    }
}
