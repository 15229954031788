export const name = 'SiteMembersBaseAspect'

export const defaultModel = {
    approvedPasswordPages: {},
    pagesJsonFileName: {}
}

export const functionLibrary = {
    isPageAllowedRuntime: (allowedPages, pageId) => allowedPages[pageId]
}

export function init() {
}
