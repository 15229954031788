import _ from 'lodash'
import {
    resolve,
    resolvePayload,
    renderToStaticMarkup,
    getTitle,
    setTitle,
    getDescription,
    setDescription,
    addSchema,
    EXTERNAL_IDENTIFIERS
} from '@wix/advanced-seo-utils/renderer'

import {
    convertDynamicPageModel,
    convertSeoModel,
    convertTpaModel,
    convertPlatformMetaTagsModel
} from '@wix/advanced-seo-utils/converters'

import {withActions} from 'carmi-host-extensions'
import {SOURCES} from './seo.sources'
import {tpaHandlersFunctionLibrary} from 'bolt-tpa/src/aspects/tpaHandlers/tpaHandlers'

// get this from aspectNames once we are there
export const name = 'SeoAspect'

export const OVERRIDE_IDENTIFIERS = {
    ...EXTERNAL_IDENTIFIERS,
    META_TAGS: 'META_TAGS'
}

export const defaultModel = {
    overrides: {
        [SOURCES.TPA]: {
            [OVERRIDE_IDENTIFIERS.TITLE]: {},
            [OVERRIDE_IDENTIFIERS.DESCRIPTION]: {},
            [OVERRIDE_IDENTIFIERS.STRUCTURED_DATA]: {},
            [OVERRIDE_IDENTIFIERS.META_TAGS]: {}
        },
        [SOURCES.UNSPECIFIED]: {
            [OVERRIDE_IDENTIFIERS.TITLE]: {},
            [OVERRIDE_IDENTIFIERS.DESCRIPTION]: {},
            [OVERRIDE_IDENTIFIERS.STRUCTURED_DATA]: {},
            [OVERRIDE_IDENTIFIERS.META_TAGS]: {}
        }
    },
    siteMetadataChangedRegisteredComps: {}
}

const _setRunTimeSchema = (setOverride, overrides, pageId, {value, source} = {}) => {
    const safeSource = resolveSource(source)
    const override = _.get(overrides, [safeSource, OVERRIDE_IDENTIFIERS.STRUCTURED_DATA, pageId], [])
    setOverride(safeSource, OVERRIDE_IDENTIFIERS.STRUCTURED_DATA, pageId, [...override, value])
    return value
}

export const functionLibrary = {
    resolvePayload,
    registerToSiteMetadataChange: withActions((aspectActions, compId, comp) => {
        aspectActions.setMetadataChangedRegisteredComp(compId, comp)
    }),
    unRegisterToSiteMetadataChange: withActions((aspectActions, compId) => {
        aspectActions.setMetadataChangedRegisteredComp(compId, undefined)
    }),
    notify: (pageName, description, getRegComps) => {
        if (pageName) {
            _.forEach(getRegComps(), comp =>
                tpaHandlersFunctionLibrary.invokeComponentMethod(
                    comp, 'sendPostMessage', {
                        intent: 'addEventListener',
                        eventType: 'SITE_METADATA_CHANGED',
                        params: {title: pageName, description}
                    }))
        }
    },
    getTags: (prioritizedData, context) => resolve([prioritizedData], context),
    getTagsMarkup: tags => renderToStaticMarkup(tags),
    setRunTimePageTitle: withActions(({setOverride}, pageId, {value, source} = {}) => {
        const safeSource = resolveSource(source)
        setOverride(safeSource, OVERRIDE_IDENTIFIERS.TITLE, pageId, value)
        return value
    }),
    setRunTimePageDescription: withActions(({setOverride}, pageId, {value, source} = {}) => {
        const safeSource = resolveSource(source)
        setOverride(safeSource, OVERRIDE_IDENTIFIERS.DESCRIPTION, pageId, value)
        return value
    }),
    setRunTimeSchema: withActions(({setOverride}, ...args) => _setRunTimeSchema(setOverride, ...args)),
    setRunTimeSchemaFromFetcher: withActions(({setOverride}, overrides, pageId, schema) => {
        _setRunTimeSchema(setOverride, overrides, pageId, {value: schema, source: SOURCES.TPA})
    }),
    setRunTimeMetaTags: withActions(({setOverride}, pageId, {value, source} = {}) => {
        const safeSource = resolveSource(source)
        setOverride(safeSource, OVERRIDE_IDENTIFIERS.META_TAGS, pageId, value)
        return value
    }),
    setWindowTitle: (windowObject, title) => {
        if (windowObject) {
            windowObject.document.title = title
            return title
        }
        return null
    },

    getTitle,
    getDescription,

    convertSeoModel,
    convertDynamicPageModel,
    convertTpaModel,

    createOverrideSchema: (currentPageId, identifiers) =>
        _.reduce(identifiers, (schema, pageIds, identifier) => {
            const value = pageIds[currentPageId]
            if (value === undefined) {
                return schema
            }
            return ({
                [OVERRIDE_IDENTIFIERS.TITLE]: () => setTitle(schema, value),
                [OVERRIDE_IDENTIFIERS.DESCRIPTION]: () => setDescription(schema, value),
                [OVERRIDE_IDENTIFIERS.STRUCTURED_DATA]: () => value.reduce((accSchema, currJsonLd) => addSchema(accSchema, currJsonLd), schema),
                [OVERRIDE_IDENTIFIERS.META_TAGS]: () => resolvePayload([schema, convertPlatformMetaTagsModel(value)])
            }[identifier] || (() => schema))()
        }, {})
}

function resolveSource(source) {
    return SOURCES[source] ? source : SOURCES.UNSPECIFIED
}
