import Color from 'color'
import coreUtils from 'santa-core-utils'
import {withActions} from '../withActions'
import {parseSvgString} from './svg'
const themeColorMatcher = /^color_([0-9]{1,2}|100)$/
const svgTagMatcher = /(<svg[^>]*)(>)/
const fillAttributeMatcher = /fill="(.*?)"/gi
const viewBoxMatcher = /viewBox="([^"]*)"/i
const {SVG_TYPES} = coreUtils.svgUtils

const isNumber = num => typeof num === 'number' && num === num // eslint-disable-line no-self-compare

const getViewBoxString = ({x, y, width, height}) => `${x} ${y} ${width} ${height}`

const getViewBoxObject = viewBox => {
    const vieBoxArr = viewBox.split(' ')
    return {
        x: vieBoxArr[0],
        y: vieBoxArr[1],
        width: vieBoxArr[2],
        height: vieBoxArr[3]
    }
}

/**
 * Get color value, use color from theme if value is a pointer, normalize color to use rgba() syntax
 * @param {object} colorsMap
 * @param {string} color
 * @param {number} [alphaOverride]
 * @returns {string}
 */
const colorParser = {
    getColor(color, colorsMap, alphaOverride) {
        // Get color from theme if the color value is represented as {color_X}
        if (/color_/.test(color)) {
            color = color.replace(/[\[\]{}]/g, '')
            color = colorsMap[color.split('_')[1]]
            color = coreUtils.cssUtils.normalizeColorStr(color)
        }

        if (color === 'none') {
            return 'transparent'
        }

        const colorObj = new Color(color)

        if (isNumber(alphaOverride)) {
            colorObj.setValues('alpha', alphaOverride)
        }

        if (colorObj.alpha() === 0) {
            return 'transparent'
        }

        return colorObj.rgbaString()
    }
}

/**
 * Add accessibility role and label from alt text
 *
 * @param {string} svgString
 * @param {string} altText
 * @param {string} id
 * @returns {string} SVG string
 */
function addA11yFeatures(svgString, altText, id) {
    const newSvgString = svgString.replace(svgTagMatcher, '$1 role="img"$2')

    if (typeof altText === 'string') {
        const svgLabelId = `${id}-svgtitle`
        return newSvgString.replace(svgTagMatcher, `$1 aria-labelledby="${svgLabelId}"$2<title id="${svgLabelId}">${altText}</title>`)
    }

    return newSvgString
}

/**
 * Tinted SVG
 * search for all 'fill' attributes and replace with tint color'.
 *
 * @param {string} fillColor
 * @param {object} colorsMap
 * @param {string} svgString
 * @return {string} SVG string
 */
function transformToTintColors(svgString, fillColor, colorsMap) {
    const resolvedColor = colorParser.getColor(fillColor, colorsMap)
    const baseColor = new Color(resolvedColor)

    return svgString.replace(fillAttributeMatcher, (full, colorToTint) => {
        const colorObj = new Color(colorToTint)

        if (isGreyscale(colorObj)) {
            const tint = 1 - (255 - colorObj.red()) / 255 // eslint-disable-line no-mixed-operators
            const rTint = Math.floor(baseColor.red() + (255 - baseColor.red()) * tint) // eslint-disable-line no-mixed-operators
            const gTint = Math.floor(baseColor.green() + (255 - baseColor.green()) * tint) // eslint-disable-line no-mixed-operators
            const bTint = Math.floor(baseColor.blue() + (255 - baseColor.blue()) * tint) // eslint-disable-line no-mixed-operators
            const tintedColor = new Color({red: rTint, green: gTint, blue: bTint})
            // return tinted color
            return `fill="${tintedColor.hexString()}"`
        }

        // no change, return original svg color
        return `fill="${colorToTint}"`
    })
}

/**
 * Check if color is greyscale
 *
 * @param colorObj
 * @returns {boolean}
 */
function isGreyscale(colorObj) {
    return colorObj.red() === colorObj.green() &&
        colorObj.red() === colorObj.blue() &&
        colorObj.red() !== 255
}

function overrideColorCssTemplate({index, color}) {
    return `svg [data-color="${index}"] {fill: ${color};}\n`
}

function getScaledSvgViewBox(svgString, svgInfo, properties = {}, layout, strokeWidth) {
    if (svgString) {
        const {svgType, viewBox, bbox} = svgInfo
        const {preserveViewBox, displayMode} = properties

        const preserveAspectRatio = displayMode !== 'stretch'
        const preserveAspectRatioString = preserveAspectRatio ? 'xMidYMid meet' : 'none'

        svgString = svgString.replace(/<svg/, `<svg preserveAspectRatio="${preserveAspectRatioString}"`)

        if (!svgString.match(viewBoxMatcher)) {
            svgString = svgType === SVG_TYPES.UGC ?
                svgString.replace(/<svg/, `<svg viewBox="${viewBox}"`) :
                svgString.replace(/<svg/, `<svg viewBox="${bbox}"`)
        }

        if (svgType !== SVG_TYPES.UGC && !preserveViewBox && bbox) {
            svgString = getScaledSvgWithStroke(strokeWidth, layout, bbox, preserveAspectRatio, svgString)
        }
    }

    return svgString
}


/**
 *
 * @param shapeStyle
 * @param layout
 * @param bbox
 * @param preserveAspectRatio
 * @param svgString
 * @returns {string}
 */
function getScaledSvgWithStroke(strokeWidth, layout, bbox, preserveAspectRatio, svgString) {
    const viewBoxValue = strokeWidth ?
        getViewBoxString(getScaledViewBox(getViewBoxObject(bbox), strokeWidth, layout, preserveAspectRatio)) :
        bbox
    const newViewBox = `viewBox="${viewBoxValue}"`

    return svgString.replace(viewBoxMatcher, newViewBox)
}


/**
 *
 * @param {{x: number, y: number, width: number, height: number}} boxBoundaries
 * @param {number} strokeWidth
 * @param {{width: number, height: number}} size, the target dom size
 * @param {boolean} maintainAspectRatio
 */
function getScaledViewBox(boxBoundaries, strokeWidth, size, maintainAspectRatio) {
    const wScale = (size.width - strokeWidth) / boxBoundaries.width
    const hScale = (size.height - strokeWidth) / boxBoundaries.height
    const aspectScale = Math.min(wScale, hScale)

    const width = size.width / (maintainAspectRatio ? aspectScale : wScale)
    const height = size.height / (maintainAspectRatio ? aspectScale : hScale)
    const x = boxBoundaries.x - (width - boxBoundaries.width) / 2 // eslint-disable-line no-mixed-operators
    const y = boxBoundaries.y - (height - boxBoundaries.height) / 2 // eslint-disable-line no-mixed-operators

    return {
        width,
        height,
        x,
        y
    }
}

export const name = 'VectorImageAspect'

export const defaultModel = {
    svgShapes: {}
}

// Runtime
export const functionLibrary = {
    scaleSvgViewBox: getScaledSvgViewBox,
    testThemeColor: val => themeColorMatcher.test(val),
    getColor: colorParser.getColor,
    addA11yFeatures,
    transformToTintColors,
    handleFetchedSvg: withActions((aspectActions, svgId, data) => {
        const parsedSvg = parseSvgString(data)
        aspectActions.addSvgShape(svgId, parsedSvg)
    }),
    overrideColorCssTemplate,
    addSvgShape: withActions(({addSvgShape}, svgId, svgShape) => addSvgShape(svgId, svgShape)),
    getSvgUrl: (svgId, mediaRootUrl) => coreUtils.svgUtils.svgIdToUrl(mediaRootUrl, svgId)
}

export function init(carmiInstance, {eventsManager, initialData}) {} // eslint-disable-line
