export const name = 'PageAspect'
export const functionLibrary = {
    syncSOSP: (updateActiveModes, getSOSPModes, activeSOSPModeId, correctSOSPModeId) => {
        // console.log('syncSOSP', getSOSPModes(), activeSOSPModeId, correctSOSPModeId)
        if (activeSOSPModeId === correctSOSPModeId) {
            return true
        }
        if (!correctSOSPModeId) {
            updateActiveModes(activeSOSPModeId, undefined)
        } else {
            updateActiveModes(correctSOSPModeId, getSOSPModes()[correctSOSPModeId])
        }
        return false
    }
}
