'use strict'

const _ = require('lodash')
const runtimeUtil = require('../../utils/runtime')
const constants = require('../../utils/constants')
const dataSchemas = require('./schemas/dataRefs')

const {
    DATA_QUERY,
    PROPERTY_QUERY,
    DESIGN_QUERY,
    STYLE_ID
} = constants.pointers.components.PROPERTY_TYPES

const {
    DATA,
    DESIGN,
    PROPERTIES,
    STYLE
} = constants.pointers.data.DATA_MAPS

const DISPLAYED_ONLY_DELIMITER = '__'
const getDisplayedId = (originalId, itemId) => `${originalId}${DISPLAYED_ONLY_DELIMITER}${itemId}`

const getMapSchemas = mapName => {
    switch (mapName) {
        case DESIGN:
            return dataSchemas.Design
        case PROPERTIES:
            return dataSchemas.Properties
        default:
            return dataSchemas.Data
    }
}

module.exports = {
    create: model => {
        const registerComponentEvent = (compId, newEventData) => {
            const action = {
                type: 'comp',
                name: newEventData.eventType,
                sourceId: compId
            }
            const behavior = {
                name: 'runCode',
                type: 'widget',
                targetId: newEventData.contextId,
                params: {
                    callbackId: newEventData.callbackId,
                    compId
                }
            }

            model.spliceRuntimeBehavior(model.runtimeActionBehaviors.length, 0, {action, behavior, compId})
        }

        const getQuery = (structure, compId, queryName) => _.replace(structure[compId][queryName], '#', '')

        const getChildrenRecursively = compId => {
            const comp = model.full.structure[compId]
            return comp.components ? [...comp.components, ..._.flatMap(comp.components, getChildrenRecursively)] : []
        }

        function handleAddedRepeaterItems(repeaterId, newItems) {
            if (!newItems.length) {
                return
            }

            const repeaterTemplateComponents = getChildrenRecursively(repeaterId)
            _.forEach(newItems, newItemId => {
                _.forEach(repeaterTemplateComponents, templateCompId => {
                    const displayedCompId = getDisplayedId(templateCompId, newItemId)

                    const templateComp = model.full.structure[templateCompId]
                    if (templateComp.dataQuery) {
                        const originalDataId = templateComp.dataQuery.replace('#', '')
                        const templateDataItem = model.full.data[DATA][originalDataId]
                        const templateOverridesId = runtimeUtil.getRuntimeId(templateCompId, originalDataId)
                        const templateOverrides = model.runtime.data[DATA][templateOverridesId]
                        const displayedItemDataId = getDisplayedId(originalDataId, newItemId)
                        const runtimeId = runtimeUtil.getRuntimeId(displayedCompId, displayedItemDataId)
                        const itemDataItem = {...templateDataItem, ...templateOverrides, id: runtimeId}
                        model.updateRuntimeDataOverrides(DATA, runtimeId, itemDataItem)
                    }

                    if (templateComp.designQuery) {
                        const originalDesignId = templateComp.designQuery.replace('#', '')
                        const templateDesignItem = model.full.data[DESIGN][originalDesignId]
                        const templateOverridesId = runtimeUtil.getRuntimeId(templateCompId, originalDesignId)
                        const templateOverrides = model.runtime.data[DESIGN][templateOverridesId]
                        const displayedItemDesignId = getDisplayedId(originalDesignId, newItemId)
                        const runtimeId = runtimeUtil.getRuntimeId(displayedCompId, displayedItemDesignId)
                        const itemDesignItem = {...templateDesignItem, ...templateOverrides, id: runtimeId}
                        model.updateRuntimeDataOverrides(DESIGN, runtimeId, itemDesignItem)
                    }

                    const templateCompRuntimeBehaviors = model.runtimeActionBehaviorsForComponent[templateComp.id]
                    if (!_.isEmpty(templateCompRuntimeBehaviors)) {
                        _.forEach(templateCompRuntimeBehaviors, actionBehavior => {
                            addActionsAndBehaviors(displayedCompId, actionBehavior)
                        })
                    }

                    if (templateComp[PROPERTY_QUERY]) {
                        const originalPropsId = templateComp[PROPERTY_QUERY].replace('#', '')
                        const templatePropsOverridesId = runtimeUtil.getRuntimeId(templateCompId, originalPropsId)
                        const templatePropsOverrides = model.runtime.data[PROPERTIES][templatePropsOverridesId]

                        if (templatePropsOverrides) {
                            const templatePropsItem = model.full.data[PROPERTIES][originalPropsId]
                            const itemPropsId = runtimeUtil.getRuntimeId(displayedCompId, originalPropsId)
                            const itemPropsItem = {...templatePropsItem, ...templatePropsOverrides, id: itemPropsId}

                            model.updateRuntimeDataOverrides(PROPERTIES, itemPropsId, itemPropsItem)
                        }
                    }
                })
            })
        }

        function handleRemovedRepeaterItems(repeaterId, removedItems) {
            if (!removedItems.length) {
                return
            }

            const repeaterTemplateComponents = getChildrenRecursively(repeaterId)
            _.forEach(removedItems, removedItemId => {
                _.forEach(repeaterTemplateComponents, templateCompId => {
                    const displayedCompId = getDisplayedId(templateCompId, removedItemId) // comp1__item2

                    const templateComp = model.full.structure[templateCompId]
                    if (templateComp.dataQuery) {
                        const originalDataId = templateComp.dataQuery.replace('#', '') // data1
                        const displayedItemDataId = getDisplayedId(originalDataId, removedItemId) // data1__item2
                        const runtimeId = runtimeUtil.getRuntimeId(displayedCompId, displayedItemDataId)
                        model.updateRuntimeDataOverrides(DATA, runtimeId)
                    }

                    if (templateComp.designQuery) {
                        const originalDesignId = templateComp.designQuery.replace('#', '')
                        const itemDesignId = getDisplayedId(originalDesignId, removedItemId)
                        model.updateRuntimeDataOverrides(DESIGN, itemDesignId)
                    }

                    const currentCompRuntimeBehaviors = model.runtimeActionBehaviorsForComponent[displayedCompId]
                    _.forEach(currentCompRuntimeBehaviors, actionBehavior => {
                        removeActionsAndBehaviors(displayedCompId, actionBehavior.action.name)
                    })

                    if (templateComp[PROPERTY_QUERY]) {
                        const originalPropsId = templateComp[PROPERTY_QUERY].replace('#', '')
                        const templatePropsOverridesId = runtimeUtil.getRuntimeId(templateCompId, originalPropsId)
                        const templatePropsOverrides = model.runtime.data[PROPERTIES][templatePropsOverridesId]

                        if (templatePropsOverrides) {
                            const itemPropsId = runtimeUtil.getRuntimeId(displayedCompId, originalPropsId)
                            model.updateRuntimeDataOverrides(PROPERTIES, itemPropsId)
                        }
                    }
                })
            })
        }

        const doRepeaterStuff = (repeaterId, originalRepeaterData, currentOverrides, newRepeaterOverrides) => {
            if (!newRepeaterOverrides.items) {
                return
            }

            const currentItems = currentOverrides && currentOverrides.items || originalRepeaterData.items
            const newItems = _.difference(newRepeaterOverrides.items, currentItems)
            const removedItems = _.difference(currentItems, newRepeaterOverrides.items)

            handleAddedRepeaterItems(repeaterId, newItems)
            handleRemovedRepeaterItems(repeaterId, removedItems)
        }

        const setData = (compId, queryName, mapName, fullPartialOverrides) => { //eslint-disable-line consistent-return
            if (!_.get(model.structure, compId)) {
                return undefined
            }

            const structureQuery = getQuery(model.structure, compId, queryName)
            const schemas = getMapSchemas(mapName)

            const setResolvedDataItem = (query, itemPartialOverrides) => {
                query = query.replace('#', '')
                const runtimeId = runtimeUtil.getRuntimeId(compId, query)

                if (_.isNull(itemPartialOverrides)) {
                    model.updateRuntimeDataOverrides(mapName, runtimeId, undefined)
                    return undefined
                }

                const currentItem = model.data[mapName][query] || {}
                const dataItemType = itemPartialOverrides.type || currentItem.type
                if (!dataItemType && mapName !== PROPERTIES) {
                    throw new Error('No data type!')
                }

                const dataOverrides = getDataOverridesWithRefs(itemPartialOverrides, currentItem, query, schemas[dataItemType])

                const runtimeDataItemId = dataItemType === 'Page' ? query : runtimeId
                const currentOverrides = model.runtime.data[mapName][runtimeDataItemId]

                if (dataItemType === 'Repeater') {
                    doRepeaterStuff(compId, currentItem, currentOverrides, dataOverrides)
                }

                model.updateRuntimeDataOverrides(mapName, runtimeDataItemId, _.assign({}, currentOverrides, dataOverrides))

                return runtimeDataItemId
            }

            const shouldResolveRef = dataItem => !(dataItem && dataItem.type === 'Page')

            function getDataOverridesWithRefs(partialOverrides, compData = {}, query, refsSchema) {
                return _.mapValues(partialOverrides, (partialValue, key) => {
                    const refSchemaValue = _.get(refsSchema, key)
                    const isRefItem = _.isBoolean(refSchemaValue) && !_.isUndefined(partialValue)
                    const isNestedRef = _.isObject(refSchemaValue) && _.isObject(partialValue)

                    if (isNestedRef) {
                        return getDataOverridesWithRefs(partialValue, compData[key], `${query}${key}`, refSchemaValue)
                    }

                    if (!isRefItem || refSchemaValue === true && _.isString(partialValue)) {
                        return partialValue
                    }

                    if (_.isArray(partialValue)) {
                        return _.map(partialValue, (refPartialOverrides, index) => setResolvedDataItem(`${query}${key}${index}`, _.assign({}, refPartialOverrides, {metaData: compData.metaData})))
                    }

                    const existingDataId = _.get(compData[key], 'id', compData[key])
                    const dataId = (existingDataId || `${query}${key}`).replace('#', '')
                    return shouldResolveRef(partialValue) ? setResolvedDataItem(dataId, partialValue) : dataId
                })
            }

            setResolvedDataItem(structureQuery || queryName, fullPartialOverrides)
        }

        const getData = (compId, queryName, mapName) => {
            const query = getQuery(model.structure, compId, queryName)

            return model.data[mapName][query]
        }

        function addActionsAndBehaviors(compId, {action, behavior}) {
            model.spliceRuntimeBehavior(model.runtimeActionBehaviors.length, 0, {action, behavior, compId})
        }

        function removeActionsAndBehaviors(compId, actionName) {
            const actionBehaviorIdx = model.runtimeActionBehaviors.findIndex(actionBehavior =>
                actionBehavior.compId === compId && actionBehavior.action.name === actionName)
            if (actionBehaviorIdx > -1) {
                model.spliceRuntimeBehavior(actionBehaviorIdx, 1)
            }
        }

        const withBatch = functionToRun => (...args) => {
            model.$startBatch()
            const val = functionToRun(...args)
            model.$endBatch()
            return val
        }

        return {
            getCompData: compId => getData(compId, DATA_QUERY, DATA),
            getCompProps: compId => getData(compId, PROPERTY_QUERY, PROPERTIES),
            getPopupContext: _.noop,
            registerComponentEvent,
            // reset: () => model.setRuntime(runtimeUtil.createEmptyRuntime()),
            setCompData: withBatch((compId, partialOverrides) => setData(compId, DATA_QUERY, DATA, partialOverrides)),
            setCompDesign: withBatch((compId, partialOverrides) => setData(compId, DESIGN_QUERY, DESIGN, partialOverrides)),
            setCompProps: withBatch((compId, partialOverrides) => setData(compId, PROPERTY_QUERY, PROPERTIES, partialOverrides)),
            updateCompState: (compId, currentOverrides, partialOverrides) => {
                const overrides = partialOverrides ? _.assign({}, currentOverrides, partialOverrides) : undefined
                model.updateRuntimeCompState(compId, overrides)
            },
            addActionsAndBehaviors,
            removeActionsAndBehaviors,
            updateCompLayout: _.noop,
            updateCompStyle: withBatch((compId, partialOverrides) => setData(compId, STYLE_ID, STYLE, partialOverrides))
        }
    }
}
