'use strict'

const _ = require('lodash')
const runtimeDalFactory = require('../runtimeDal')
const {withActions} = require('carmi-host-extensions')

const displayedDal = require('../displayedDal')

const buildRuntimeDAL = (carmiInstance, eventsManager) => runtimeDalFactory.create(carmiInstance, displayedDal.create(carmiInstance), eventsManager)

module.exports = {
    name: 'RuntimeAspect',
    defaultModel: {},
    functionLibrary: {
        updateCompState: (runtimeDal, runtimeStateMap, compId, partialOverrides) =>
            runtimeDal.updateCompState(compId, _.get(runtimeStateMap, compId), partialOverrides),
        setCompData: (runtimeDal, compId, partialOverrides) =>
            runtimeDal.setCompData(compId, partialOverrides),
        setCompProps: (runtimeDal, compId, partialOverrides) =>
            runtimeDal.setCompProps(compId, partialOverrides),
        resetRuntimeOverrides: withActions((actions, dataMap, contextId, getOverridesToRemove) => {
            const overridesToRemove = getOverridesToRemove()
            _.forEach(overridesToRemove, (override, id) => {
                actions.updateRuntimeDataOverrides(dataMap, id, undefined)
            })
            actions.setContextWasReset(contextId, true)
        })
    },
    init: (carmiInstance, {eventsManager}) => {
        const runtimeDAL = buildRuntimeDAL(carmiInstance, eventsManager)
        carmiInstance.setRuntimeDalInstance(runtimeDAL)
    }
}
