'use strict'

const santaDataRefs = require('./santaDataRefs.json')
const wixUiSantaDataRefs = require('./wixUiSantaDataRefs.json')

module.exports = {
    Data: {
        ...wixUiSantaDataRefs.Data,
        ...santaDataRefs.Data
    },
    Design: {
        ...wixUiSantaDataRefs.Design,
        ...santaDataRefs.Design
    },
    Properties: {
        ...wixUiSantaDataRefs.Properties,
        ...santaDataRefs.Properties
    }
}
