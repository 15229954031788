import {fonts} from 'santa-core-utils'

export const name = 'FontsAspect'

export const defaultModel = {
    fontsCss: {}
}

export const functionLibrary = {
    getFontsUrlWithParams: (usedFonts, ...args) => fonts.getFontsUrlWithParams(usedFonts || fonts.getFontsMetaData(), ...args)
}

export function init() {
}
