import _ from 'lodash'
import {withActions} from 'carmi-host-extensions'

const HEAD = 'head'
const BODY_START = 'bodyStart'
const BODY_END = 'bodyEnd'
const locations = [HEAD, BODY_START, BODY_END]
export const name = 'codeEmbed'
export const NODE_LOCATION_STATUS = {
    VALID: 0,
    MISSING: 1,
    CORRUPTED: 2
}


function validateNodeLocations(locationObj) {
    if (!locationObj) {
        return NODE_LOCATION_STATUS.MISSING
    }

    const indexStart = _.get(locationObj, ['index', 'start'])
    const indexEnd = _.get(locationObj, ['index', 'end'])
    const nodesFound = _.get(locationObj, ['nodes', 'start']) && _.get(locationObj, ['nodes', 'end'])
    if (indexStart && indexEnd && indexStart < indexEnd && nodesFound) {
        return NODE_LOCATION_STATUS.VALID
    }
    return NODE_LOCATION_STATUS.CORRUPTED
}

function getContextByLocation(location, windowObj) {
    switch (location) {
        case HEAD: {
            return windowObj.document.head
        }
        case BODY_START:
        case BODY_END: {
            return windowObj.document.body
        }
        default: return null
    }
}

const locationToCommentBegin = {
    [HEAD]: 'head html embeds start',
    [BODY_START]: 'body start html embeds start',
    [BODY_END]: 'body end html embeds start'
}

const locationToCommentEnd = {
    [HEAD]: 'head html embeds end',
    [BODY_START]: 'body start html embeds end',
    [BODY_END]: 'body end html embeds end'
}

function getCommentNodesIndex(location, windowObj) {
    const commentNodeBegin = locationToCommentBegin[location]
    const commentNodeEnd = locationToCommentEnd[location]
    const context = getContextByLocation(location, windowObj)
    let commentStartIndex = null

    let locationObj = null

    for (let i = 0; i < context.childNodes.length; i++) {
        const node = context.childNodes[i]
        if (node.nodeType === windowObj.Node.COMMENT_NODE) {
            if (node.textContent === commentNodeBegin) {
                commentStartIndex = i
                locationObj = {location}
                _.set(locationObj, ['index', 'start'], commentStartIndex)
                _.set(locationObj, ['nodes', 'start'], node)
            } else if (node.textContent === commentNodeEnd) {
                locationObj = _.merge(locationObj, {index: {end: i}, nodes: {end: node}})
                break
            }
        }
    }

    return locationObj
}

const isLoadOnce = script => script.loadOnce && !script.pages
const shouldRemoveScriptFromDom = script => !isLoadOnce(script)

function removeNodes(context, nodeListToDelete) {
    for (let i = 0; i < nodeListToDelete.length; ++i) {
        context.removeChild(nodeListToDelete[i])
    }
}

function getNodesBetween(nodesCollection, commentStartIndex, commentEndNode, windowObj) {
    return _(nodesCollection)
        .slice(commentStartIndex + 1, commentEndNode)
        .filter({nodeType: windowObj.Node.ELEMENT_NODE})
        .value()
}

const addBodyCommentNodes = windowObj => {
    const siteContainer = windowObj.document.getElementById('SITE_CONTAINER')
    windowObj.document.body.insertBefore(windowObj.document.createComment(locationToCommentBegin[BODY_START]), siteContainer)
    windowObj.document.body.insertBefore(windowObj.document.createComment(locationToCommentEnd[BODY_START]), siteContainer)
    windowObj.document.body.appendChild(windowObj.document.createComment(locationToCommentBegin[BODY_END]))
    windowObj.document.body.appendChild(windowObj.document.createComment(locationToCommentEnd[BODY_END]))
}

const loadScriptsToBody = (embeds, pageId, windowObj) => {
    const commentNodeLocations = _.map([BODY_START, BODY_END], location => getCommentNodesIndex(location, windowObj))
    _.forEach(commentNodeLocations, nodeLocation => {
        addNewScripts(embeds, nodeLocation.location, nodeLocation.nodes.start, [], [], pageId, windowObj)
    })
}

function addNewScripts(embeds, location, commentNodeBegin, remainingScriptsIdsInDom, nodesInDom, pageId, windowObj) {
    let nodeToAddAfter = commentNodeBegin
    const context = getContextByLocation(location, windowObj)

    let nextAvailableScriptIndexInDom = 0
    _.forEach(getScriptsForPage(embeds, location, pageId), script => {
        const tempDiv = windowObj.document.createElement('div')

        if (remainingScriptsIdsInDom.length > 0 && remainingScriptsIdsInDom[nextAvailableScriptIndexInDom] === script.id) {
            nodeToAddAfter = nodesInDom[nextAvailableScriptIndexInDom]
            nextAvailableScriptIndexInDom++
        } else {
            const element = windowObj.document.createElement('div')
            element.innerHTML = script.content.html
            _copyChildren(element, tempDiv, windowObj)

            while (tempDiv.children.length) {
                context.insertBefore(tempDiv.children[0], nodeToAddAfter.nextSibling)
            }
            nodeToAddAfter = nodeToAddAfter.nextSibling
        }
    })
}

function getScriptsForPage(embeds, location, pageId) {
    return _(embeds)
        .filter({position: location})
        .filter(embed => embed.embedType === 'custom' || embed.embedType === 'verificationCode')
        .filter(embed => !embed.pages || _.some(embed.pages, page => page === pageId) || isLoadOnce(embed))
        .value()
}

function _copyChildren(fromElem, toElem, windowObj) {
    _.forEach(fromElem.childNodes, orig => {
        let copy = null
        if (orig.nodeType === windowObj.Node.ELEMENT_NODE) {
            copy = windowObj.document.createElement(orig.tagName)
            _.forEach(orig.attributes, attr => {
                copy.setAttribute(attr.name, attr.value)
            })
            _copyChildren(orig, copy, windowObj)
        } else if (orig.nodeType === windowObj.Node.TEXT_NODE) {
            copy = windowObj.document.createTextNode(orig.textContent)
        } else if (orig.nodeType === windowObj.Node.COMMENT_NODE) {
            copy = windowObj.document.createComment(orig.textContent)
        }
        if (copy) {
            toElem.appendChild(copy)
        }
    })
}


function onSiteReady({setCommentNodeStatus}, embeds, getCurrentPageId, windowObj) {
    if (typeof windowObj === 'undefined') {
        return
    }

    const pageId = getCurrentPageId()
    const commentNodeStatus = _.map(locations, location => validateNodeLocations(getCommentNodesIndex(location, windowObj)))

    if (_.includes(commentNodeStatus, NODE_LOCATION_STATUS.CORRUPTED)) {
        setCommentNodeStatus(NODE_LOCATION_STATUS.CORRUPTED)
        // coreUtils.loggingUtils.logger.reportBI(this._siteData, errors.INVALID_CODE_EMBED_SCRIPT, {})
    } else if (_.includes(commentNodeStatus, NODE_LOCATION_STATUS.MISSING)) {
        setCommentNodeStatus(NODE_LOCATION_STATUS.VALID)
        addBodyCommentNodes(windowObj)
        loadScriptsToBody(embeds, pageId, windowObj)
    } else {
        setCommentNodeStatus(NODE_LOCATION_STATUS.VALID)
    }
}

function onUrlChange(embeds, pageId, dyingPageId, windowObj) {
    if (typeof windowObj === 'undefined') {
        return
    }

    const commentNodeLocations = _.map(locations, location => getCommentNodesIndex(location, windowObj))
    //Collect nodes to remove
    const nodesToDelete = {}
    const remainingScriptsIdsInDom = {}
    const remainingNodesInDom = {}
    _.forEach(commentNodeLocations, commentNodeLocation => {
        const location = commentNodeLocation.location
        const context = getContextByLocation(location, windowObj)
        const nodesInDom = getNodesBetween(context.childNodes, commentNodeLocation.index.start, commentNodeLocation.index.end, windowObj)
        const scriptsInServer = getScriptsForPage(embeds, location, dyingPageId)
        nodesToDelete[location] = []
        remainingScriptsIdsInDom[location] = []
        remainingNodesInDom[location] = []
        for (let i = 0; i < scriptsInServer.length; ++i) {
            if (shouldRemoveScriptFromDom(scriptsInServer[i])) {
                nodesToDelete[location].push(nodesInDom[i])
            } else {
                remainingScriptsIdsInDom[location].push(scriptsInServer[i].id)
                remainingNodesInDom[location].push(nodesInDom[i])
            }
        }
    })

    //Remove nodes
    for (let i = 0; i < locations.length; ++i) {
        const location = locations[i]
        const context = getContextByLocation(location, windowObj)
        removeNodes(context, nodesToDelete[location])
    }

    //Add new nodes
    _.forEach(commentNodeLocations, nodeLocation => {
        addNewScripts(embeds, nodeLocation.location, nodeLocation.nodes.start, remainingScriptsIdsInDom[nodeLocation.location], remainingNodesInDom[nodeLocation.location], pageId, windowObj)
    })
}

export const defaultModel = {
    commentNodeStatus: 0
}
export const functionLibrary = {
    onSiteReady: withActions(onSiteReady),
    onUrlChange
}
