export const name = 'HtmlComponentAspect'
import {withActions} from 'carmi-host-extensions/src/aspects/withActions'
const _ = require('lodash')

export const defaultModel = {
    windows: {},
    messages: {}
}

export const functionLibrary = {
    postMessageToHtmlComponent: withActions((aspectActions, compWindow, compId, msg) => {
        if (compWindow) {
            compWindow.postMessage(msg, '*')
        } else {
            aspectActions.addPendingMessageComp(_.uniqueId('htmlComp'), {compId, msg})
        }
    }),
    flushMessageAndClear: withActions((aspectActions, compWindow, msg, msgId) => {
        if (compWindow) {
            compWindow.postMessage(msg, '*')
            aspectActions.addPendingMessageComp(msgId, undefined)
        }
    })

}
