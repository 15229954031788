import {linkRenderer} from 'coreUtils' //eslint-disable-line import/no-unresolved
import {wixUrlParser} from 'warmupUtils' //eslint-disable-line import/no-unresolved
import {siteConstants} from 'santa-core-utils'

import _ from 'lodash'

export const name = 'NavigationAspect'

const parsePageItemAdditionalData = str => str
    .split(' ')
    .map(keyValue => keyValue.split(':'))
    .reduce((acc, [key, value]) => ({...acc, [key]: value}), {})

export const functionLibrary = {
    parsePageItemAdditionalData,
    renderLink: (linkData, linkRenderInfo, rootNavigationInfo) => linkRenderer.renderLink(linkData, linkRenderInfo, rootNavigationInfo),
    handleHttps: str => str.replace(/^https:/, 'http:'),
    renderImageZoomLink: (linkRenderInfo, rootNavigationInfo, imageData, propertyQuery) => linkRenderer.renderImageZoomLink(linkRenderInfo, rootNavigationInfo, imageData, null, propertyQuery, null),
    navigateTo: (startNavigationProcess, renderInfo, focusedRootId, modelData, nextNavigationInfo) => {
        const {
            href,
            // target, //not needed
            pageItemAdditionalData,
            anchorData,
            noUrlChangeAttr,
            isPopup
            // isChangingUrl,
            // isKeepingRoots
        } = nextNavigationInfo

        const url = noUrlChangeAttr || href
        const navInfo = url ? wixUrlParser.parseUrl(renderInfo, url) : nextNavigationInfo
        navInfo.isPopup = isPopup || _.get(modelData, ['document_data', navInfo.pageId, 'isPopup'])
        navInfo.anchorData = anchorData
        if (focusedRootId === navInfo.pageId && !anchorData) {
            navInfo.anchorData = siteConstants.PAGE_ANCHORS.TOP_ANCHOR_ID
        }
        navInfo.navigationTime = Date.now()
        navInfo.pageItemAdditionalData = pageItemAdditionalData

        startNavigationProcess(navInfo)
    },
    isExternalUrl: (renderInfo, url) => !wixUrlParser.parseUrl(renderInfo, url),
    isPermaLinkByRootId: () => false,
    getUrl: (linkRenderInfo, options) => {
        const _linkRenderInfo = _.cloneDeep(linkRenderInfo) //todo: should search in wixUrlParser.getUrl who's mutating siteData
        const {pageInfo, forceAddPageInfo, cleanQuery, baseUrl, urlMapping} = options
        return wixUrlParser.getUrl(_linkRenderInfo, pageInfo, forceAddPageInfo, cleanQuery, baseUrl, urlMapping)
    },
    parseUrl: (linkRenderInfo, url) => wixUrlParser.parseUrl(linkRenderInfo, url),
    openPopup: (windowObject, url, target, params) => {
        windowObject.open(url, target, params)
    }
}

