/*eslint no-unused-vars:0*/

import {browserDetection, cookieUtils} from 'santa-core-utils'
import _ from 'lodash'
import {withActions} from '../withActions'
export const name = 'BrowserAspect'

export const defaultModel = {
    cookies: {}
}


// Runtime
export const functionLibrary = {
    detectBrowser: userAgent => browserDetection(userAgent),
    majorVersion: version => Number((version || '').split('.')[0] || 0),
    addCookie: withActions((aspectActions, cookieData) => {
        cookieUtils.setCookie(cookieData.name, cookieData.value, cookieData.time, cookieData.domain, cookieData.path, cookieData.secure)
        aspectActions.addCookie(cookieData.name, cookieData.value)
    }),
    deleteCookie: withActions((aspectActions, currentCookies, cookieToDelete) => {
        cookieUtils.deleteCookie(cookieToDelete.name, cookieToDelete.domain, cookieToDelete.path)
        if (currentCookies[cookieToDelete.name]) {
            aspectActions.setCookies(_.omit(currentCookies, cookieToDelete.name))
        }
    }),
    setCookies: withActions((aspectActions, cookieStr) => {
        const cookies = cookieUtils.parseCookieString(cookieStr)
        aspectActions.setCookies(cookies)
    })
}

export function init(carmiInstance, {eventsManager}) {

}

