import * as SiteButton from './aspects/siteButton/siteButton'
import * as MediaContainer from './aspects/mediaContainer/mediaContainer'
import * as Page from './aspects/page/page'
import * as PinterestWidget from './aspects/pinterestWidget/pinterestWidget'
import * as InlinePopup from './aspects/inlinePopup/inlinePopup'
import * as HtmlComponent from './aspects/htmlComponent/htmlComponent'
import * as VkShare from './aspects/vkShare/vkShare'
import * as SlideShow from './aspects/slideShow/slideshow'
import * as QuickActionBar from './aspects/quickActionBar/quickActionBar'
import * as SliderGallery from './aspects/sliderGallery/sliderGallery'

export const runtime = {
    PinterestWidget,
    InlinePopup,
    SiteButton,
    HtmlComponent,
    VkShare,
    MediaContainer,
    Page,
    SlideShow,
    QuickActionBar,
    SliderGallery
}
