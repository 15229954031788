import {AnimationsInstance, BrowserFlags} from 'common-types'
import {aspectNames} from 'common-types'
import * as _ from 'lodash'

export const name = aspectNames.BgScrubAspect
export interface ScrubArgs {
    compId: string
    element: Element
    name: string

    duration: number
    delay: number

    params: {
        browserFlags: BrowserFlags,
        viewPortHeight: number
        componentHeight: number
    }
}

const scrubTickers = new WeakMap()

export const functionLibrary = {
    scrubCreate(args: ScrubArgs, instance: AnimationsInstance) {
        if (instance) {
            return {
                sequence: instance.animate(args.name, [args.element], args.duration, args.delay, {
                    ...args.params,
                    suppressReactRendering: false,
                    forgetSequenceOnComplete: false,
                    paused: true
                }),
                name: args.name,
                compId: args.compId
            }
        }
        return undefined
    },

    scrubDestroy(sequence, instance: AnimationsInstance) {
        instance.kill(sequence, 0)
        return null
    },

    scrubProgress(sequence, {offset, isVisible, maxTravel}, scrollY) {
        // TODO: should not update if not in viewport
        const pos = Math.max(0, scrollY) + offset
        const progress = maxTravel ? pos / maxTravel : 0
        sequence.progress(progress)
    },

    scrubAdvance(scrubbers, scrollY) {
        scrubbers.forEach(s => s(scrollY))
    },

    scrubTick(advance, windowObject, dummyScrubbers?) {
        if (!windowObject || !_.isFunction(windowObject.requestAnimationFrame)) {
            return
        }

        const pending = scrubTickers.get(windowObject)
        if (pending) {
            return
        }

        scrubTickers.set(windowObject, windowObject.requestAnimationFrame(() => {
            advance(windowObject.pageYOffset)
            scrubTickers.delete(windowObject)
        }))
    }
}
export function init({}, {eventsManager, initialData: {onScroll}}) {
    eventsManager.on('windowScroll', onScroll)
}
