const name = 'ScreenInAspect'
const SCREEN_IN_ACTION = 'screenIn'

const defaultModel = {}

const currentTargets = new WeakMap()

function screenInHandler(entry, compId) {
    if (entry.visible) {
        // trigger animation
        this.trigger([{
            compId,
            action: SCREEN_IN_ACTION
        }])
    }
}

const functionLibrary = {
    screenInOnPageMount: (pageRef, callback) => {
        if (pageRef) {
            Promise.resolve().then(callback)
        }
    },
    resetAnimationState: (animationDefs, animationManager, primaryPageId) => {
        if (animationManager) {
            animationManager.initAction(SCREEN_IN_ACTION, primaryPageId)
        }
    },
    updateViewportRegistry: (animationDefs, animationManager, viewportRegistry) => {
        if (!animationManager) {
            return
        }

        if (!currentTargets.has(animationDefs)) {
            currentTargets.set(animationDefs, {})
        }

        const targetsCache = currentTargets.get(animationDefs)

        // add new and register
        Object.keys(animationDefs).forEach(id => {
            if (!targetsCache[id]) {
                const animationName = animationDefs[id][SCREEN_IN_ACTION][0].name
                const threshold = animationManager.animationProperties[animationName].viewportThreshold
                // register
                viewportRegistry.register(name, id, {
                    handler: screenInHandler.bind(animationManager),
                    threshold
                })
                targetsCache[id] = threshold || true
            }
        })

        // delete old and unregister
        Object.keys(targetsCache).forEach(prevId => {
            if (!animationDefs[prevId]) {
                const thresholdCache = targetsCache[prevId]
                const threshold = thresholdCache && thresholdCache !== true ? thresholdCache : null

                // unregister
                viewportRegistry.unregister(name, prevId, threshold)
                delete targetsCache[prevId]
            }
        })
    }
}

export {
    name,
    defaultModel,
    SCREEN_IN_ACTION,
    functionLibrary
}
