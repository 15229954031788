import * as performanceAspect from '../aspects/performance/performance'
import * as externalAspect from '../aspects/external/external'
import * as hostApiAspect from '../aspects/hostApi/hostApi'
import {runtime as HostExtensionsRuntime} from 'carmi-host-extensions'
import {runtime as ComponentsExtensionsRuntime} from 'components-extensions'
import * as languageAspect from '../aspects/language/language'
import * as multilingual from '../aspects/multilingualModel/multilingual'
import * as NavigationRuntime from '../aspects/navigation/navigation'
import * as CompScrollHandlerRuntime from '../aspects/compScrollHandler/compScrollHandler'
import * as ServiceTopologyRuntime from '../aspects/serviceTopology/serviceTopology'
import * as ClientSpecMapRuntime from '../aspects/clientSpecMap/clientSpecMap'
import * as experimentAspect from '../aspects/experiment/experiment'
import * as SeoRuntime from '../aspects/seo/seo'
import * as CodeEmbed from '../aspects/codeEmbed/codeEmbed'
import * as SessionRuntime from '../aspects/session/session'
import * as SiteBackgroundRuntime from '../aspects/siteBackground/siteBackground'
import * as HostSpecificFontsRuntime from '../aspects/hostSpecificFonts/hostSpecificFonts'
import * as AnalyticsRuntime from '../aspects/analytics/analytics'
import * as platformAspect from '../aspects/platform/workersWrapper'
import * as PagesRequestsRuntime from '../aspects/pagesRequests/pagesRequests'
import * as PlatformMessageHandle from '../aspects/platform/incoming/PlatformMessageHandle'
import * as apiCallsHandler from '../aspects/platform/incoming/apiCallsHandler'
import * as PlatformMessageDispatch from '../aspects/platform/outgoing/platformMessagesDispatch'
import {SiteMembersBaseRuntime, SiteMembersRuntime} from 'site-members'
import {TPARuntime, TPAWidgetNativeRuntime} from 'bolt-tpa'
import * as ComponentStyleRuntime from '../aspects/componentStyle/componentStyle'
import * as VisualFocus from '../aspects/visualFocus/visualFocus'
import * as Anchor from '../aspects/anchor/anchor'
import * as VerticalAnchorsMenu from '../aspects/verticalAnchorsMenu/verticalAnchorsMenu'
import * as SiteRootRuntime from '../aspects/siteRoot/siteRoot'
import * as BI from '../aspects/bi/bi'
import * as ActivityRuntime from '../aspects/activity/activity'
import * as PopupPage from '../aspects/popupPage/popupPage'
import * as NavigationBase from '../aspects/navigation/navigationBase'
import * as PageTransitionRuntime from '../aspects/pageTransition/pageTransition'
import * as NavigateToRenderedLink from '../aspects/navigation/navigateToRenderedLink'
import * as siteAspectRuntime from '../aspects/site/site'
import * as qaAspectRuntime from '../aspects/qa/qa'

export const BoltRuntimes = [
    performanceAspect,
    externalAspect,
    hostApiAspect,
    multilingual,
    ...Object.values(ComponentsExtensionsRuntime),
    ...Object.values(HostExtensionsRuntime),
    NavigationRuntime,
    CompScrollHandlerRuntime,
    ServiceTopologyRuntime,
    siteAspectRuntime,
    ClientSpecMapRuntime,
    SiteMembersRuntime,
    SiteMembersBaseRuntime,
    SiteBackgroundRuntime,
    TPARuntime,
    TPAWidgetNativeRuntime,
    PagesRequestsRuntime,
    HostSpecificFontsRuntime,
    experimentAspect,
    languageAspect,
    SeoRuntime,
    CodeEmbed,
    SessionRuntime,
    platformAspect,
    PlatformMessageHandle,
    apiCallsHandler,
    PlatformMessageDispatch,
    ComponentStyleRuntime,
    VisualFocus,
    Anchor,
    VerticalAnchorsMenu,
    SiteRootRuntime,
    ActivityRuntime,
    PageTransitionRuntime,
    AnalyticsRuntime,
    BI,
    PopupPage,
    NavigateToRenderedLink,
    NavigationBase,
    qaAspectRuntime
]
