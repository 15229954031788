import {withActions} from 'carmi-host-extensions/src/aspects/withActions'

export const name = 'designDataChangeAspect'

export const functionLibrary = {
    triggerAnimationOnChange: withActions(({updatePreviousDesign}, compId, compDesign, getAllPrevDesignDataValues) => {
        if (getAllPrevDesignDataValues()[compId]) {
            // const prevDesignData = JSON.parse(allPrevDesignDataValues()[compId])
            // TODO BOLT-298 - run animations based on prevDesignData
        }
        updatePreviousDesign(compId, JSON.stringify(compDesign))
    })
}

export const defaultModel = {

}
