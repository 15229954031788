import {withActions} from '../withActions'
import {aspectNames} from 'common-types'

export const name = aspectNames.ScrollScrubAspect

export const defaultModel = {
    prevScrollPosition: {x: 0, y: 0},
    prevScrollDirection: '',
    accumulatedScroll: 0
}

const SCROLL_DOWN_THRESHOLDS = {
    HeaderFadeOut: 200, // px
    HeaderHideToTop: 400 // px
}
const SCROLL_UP_THRESHOLD = 100 //px

const sequenceDefaults = {
    suppressReactRendering: false,
    forgetSequenceOnComplete: false,
    paused: true
}
const limitBetweenZeroAndOne = num => Math.max(Math.min(num, 1), 0)
const limitScrollValue = scrollValue => Math.max(scrollValue, 0)

function getSequenceProgress(direction, position, prevPosition, lastSequenceProgress, height) {
    const top = limitScrollValue(position.y)
    const prevTop = limitScrollValue(prevPosition.y)
    const scrollPercentage = Math.abs(prevTop - top) / height // scrollDelta, relative to header's height
    const sign = direction === 'DOWN' ? 1 : -1
    const sequenceDelta = sign * scrollPercentage * 0.5
    return limitBetweenZeroAndOne(lastSequenceProgress + sequenceDelta)
}

interface AnimationSequence {}

export const functionLibrary = {
    setPrevious: withActions(({setPrevPosition, setPrevDirection, setAccumulatedScroll}, position, direction, accumulatedScroll) => {
        setPrevPosition(position)
        setPrevDirection(direction)
        setAccumulatedScroll(accumulatedScroll)
    }),

    createSequence({name: animationName, element, duration, delay, params} : {name: string, element: Element, duration: number, delay: number, params: any, targetId: string}, instance): AnimationSequence {
        if (!instance) {
            return null
        }
        const sequence = instance.sequence(sequenceDefaults)
        sequence.add(instance.animate(animationName, element, duration, delay, params))
        return sequence.get()
    },

    killSequence(sequence, instance) {
        instance.kill(sequence, 0)
    },

    scrubSequenceWithScroll(sequence, animation, {direction, position, prevPosition}) {
        const progress = sequence.progress()
        const newProgress = getSequenceProgress(direction, position, prevPosition, progress, animation.params.compMeasures.height)
        if (newProgress !== progress) {
            sequence.progress(newProgress)
        }
    },

    animateAfterScroll(sequence, animation, {direction, prevDirection, accumulatedScroll}) {
        const isScrollingDown = direction === 'DOWN'
        const sequenceProgress = sequence.progress()
        const shouldAnimateForward = isScrollingDown && sequenceProgress < 1 &&
            accumulatedScroll > SCROLL_DOWN_THRESHOLDS[animation.name]

        const shouldAnimateReverse = !isScrollingDown && sequenceProgress > 0 &&
            accumulatedScroll > SCROLL_UP_THRESHOLD

        if (shouldAnimateForward) {
            sequence.play()
        } else if (shouldAnimateReverse) {
            sequence.reverse()
        }
    }

}

