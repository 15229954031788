import {withActions} from '../withActions'

export const name = 'WindowClickEventAspect'

export const defaultModel = {
    registeredCompsIds: {}
}

const triggerOnDocumentClick = (compRef, event) => {
    if (compRef && compRef.onDocumentClick) {
        compRef.onDocumentClick(event)
    }
}

export const functionLibrary = {
    registerToDocumentClickEvent: withActions(({setClickEventListener}, compId, compRef) => {
        setClickEventListener(compId, compRef)
    }),
    unRegisterToDocumentClickEvent: withActions(({setClickEventListener}, compId) => setClickEventListener(compId, undefined)),
    propagateDocumentClickEvent: (compRefs, event) => compRefs.forEach(compRef => triggerOnDocumentClick(compRef, event)),
    registerToDocumentClickEventById: withActions(({setClickEventListener}, compRefs, compId) => {
        const compRef = compRefs[compId]
        setClickEventListener(compId, compRef)
    })
}

export function init({}, {eventsManager, initialData: {propagateDocumentClickEvent}}) {
    eventsManager.on('documentClick', event => propagateDocumentClickEvent(event))
}
