import _ from 'lodash'
export {handleName as name} from '../platform.schema'
import {functionLibrary as PlatformHandlers} from './platformHandlers'
import {resolveData} from '../services/resolveData'
import {handleBatchCommand} from './handleBatchCommand'
import {getResponseMessage} from './apiCallsHandler'
const coreUtilsLib = require('coreUtils')

const CommandTypes = {
    State: 'stateChanged',
    Data: 'dataChanged',
    Design: 'designChanged',
    Props: 'propsChanged',
    EventRegister: 'registerEvent',
    Layout: 'layoutChanged',
    Behavior: 'executeBehavior',
    Style: 'styleChanged',
    ExecuteBatch: 'executeBatch'
}

function handleCommand({runtimeDal, resolverGeneralInfo, handleBehavior, wixCodeAppApi}, contextId, message) {
    switch (message.command) {
        case CommandTypes.Data:
            message.data = resolveData(runtimeDal, message.compId, message.data, resolverGeneralInfo)
            runtimeDal.setCompData(message.compId, message.data)
            break
        case CommandTypes.Props:
            runtimeDal.setCompProps(message.compId, message.data)
            coreUtilsLib.animationFrame.request(() => {
                sendResponse(message, wixCodeAppApi[contextId], CommandTypes.Props)
            }) //this is roughly how it's done in santa
            break
        case CommandTypes.Design:
            runtimeDal.setCompDesign(message.compId, message.data)
            break
        case CommandTypes.Behavior:
            handleBehavior(_.assign({}, message.data, {callback: sendResponse.bind(null, message, wixCodeAppApi[contextId], CommandTypes.Behavior)}))
            break
        case CommandTypes.Layout:
            throw new Error('Command type is not implemented', message.command)
        case CommandTypes.Style:
            runtimeDal.updateCompStyle(message.compId, message.data)
            break
        case CommandTypes.ExecuteBatch:
            handleBatchCommand(runtimeDal, message.data, resolverGeneralInfo)
            break
        case CommandTypes.EventRegister:
            runtimeDal.registerComponentEvent(message.compId, message.data)
            break
    }
}

function sendResponse(message, wixCodeAppApi, commandName, data) {
    if (!message.callbackId) {
        return
    }
    const dataToSend = {
        command: commandName,
        compId: message.compId
    }
    if (data) {
        _.merge(dataToSend, data)
    }

    wixCodeAppApi.sendMessage(getResponseMessage(message, dataToSend))
}

function handleSystemMessage(messageHandleParams, contextId, message) {
    switch (message.type) {
        case 'widget_ready':
            messageHandleParams.setIsReady(contextId, true)
            break
        case 'console':
            console.log('WORKER CONSOLE', message.data)
            break
        case 'wix_code_iframe_command':
            handleCommand(messageHandleParams, contextId, message)
            break
        case 'REQUEST_API':
            if (!messageHandleParams.isInSsr) {
                messageHandleParams.requireFn('pmrpc', pmrpc => {
                    const apiName = `viewer_platform_public_api_${message.appDefId}_${message.workerId}`
                    pmrpc.api.request(apiName, {target: {
                        postMessage: (msg, filter, ports) => {
                            messageHandleParams.api.sendMessage(msg, ports)
                        },
                        addEventListener: (evtType, handler) => {
                            if (evtType === 'message') {
                                messageHandleParams.api.listen(handler)
                            }
                        }
                    }})
                        .then(api => pmrpc.api.set(apiName, api))
                })
            }
            break
        //we set this data, but we do not use it for now, cause it will force us to postpone the boostrap message which isn't a good idea for sure
        case 'wix_code_warmup_data':
            const controllerId = _.get(message, 'data.controllerId')
            const data = _.get(message, 'data.data')
            messageHandleParams.setUserWarmup(controllerId, data)
            break
        default: 
            console.log('not implemented', message.type, message)
    }
}

function setStorageAndUpdateWorkers({wixCodeAppApi, storage}, message) {
    const {type, key, value} = message.data
    storage.setItem(type, key, value)
    const storageUpdatedMessage = {
        type: 'storageWasUpdated',
        storageValue: storage.serialize()
    }
    _.forEach(wixCodeAppApi, worker => {
        worker.sendMessage(storageUpdatedMessage)
    })
}

function handleBrowserApiMessage(messageHandleParams, message) {
    switch (message.type) {
        case 'setToStorage':
            // console.log('setToStorage', message)
            setStorageAndUpdateWorkers(messageHandleParams, message)
            break
        default:
            console.log('not implemented', message.intent, message)
    }
}

export const defaultModel = {
    isReady: {},
    apiCalls: {},
    userWarmup: {}
}

export const functionLibrary = {
    deleteIsReadyEntry: (contextId, setIsReady) => {
        setIsReady(contextId, false)
        return false
    },

    handlePlatformMessage: (messageHandleParams, contextId, ...args) => {
        if (args.length > 1) {
            console.log('OHOHOHOHOHOHOHOHOHOHOHHOHOHOHOHOHO', 'too many args!!!!!', args)
        }
        const message = args[0].data

        switch (message.intent) {
            case 'WIX_CODE_SITE_API':
                const uniqueMessageId = _.uniqueId('wixCodeApiCall-')
                message.__uniqueId = uniqueMessageId
                message.__cId = contextId
                message.__api = messageHandleParams.api
                messageHandleParams.setApiCallMessage(message.__uniqueId, message)
                break
            case 'WIX_CODE':
                handleSystemMessage(messageHandleParams, contextId, message)
                break
            case 'BROWSER_API':
                handleBrowserApiMessage(messageHandleParams, message)
                break
            default: 
                console.log('not implemented', message.intent, message)
        }
    },
    ...PlatformHandlers
}
