import _ from 'lodash'
import events from './events.json'
import {DIALOGS} from './constants'
import {withActions} from 'carmi-host-extensions/src/aspects/withActions'
import siteMembersApis from './runtime/siteMembersApis'
import dialogProps from './runtime/dialogProps'
export const name = 'SiteMembersAspect'

export const defaultModel = {
    loaded: false,
    isSiteMembersDialogsOpenAllowed: true,
    siteMember: {details: null},
    approvedPasswordPages: {},
    pagesJsonFileName: {},
    logoutState: false,
    memberDetailsChangeRegisteredComps: {},
    memberLoginCallbacks: {},
    options: null,
    dialogOptions: null,
    redirectRestricted: null
}

export const functionLibrary = {
    withBI: ({reportSmBi, event, data}, cb = _.noop) => {
        reportSmBi(event, data)
        cb()
    },
    setDialogOnNextRender: withActions(({setDialogOptions}, dialogType, nextPageId) => {
        if (dialogType) {
            const dialogOptions = nextPageId ? {dialogType, nextPageId} : {dialogType}
            setDialogOptions(dialogOptions)
        }
    }),
    setInitialState: withActions(({setLoaded, setDialogOptions}, {loaded, initialDialog, currentUrlPageId}) => {
        if (!loaded && initialDialog) {
            setDialogOptions({dialogType: initialDialog, nextPageId: currentUrlPageId, initial: true})
        }
        setLoaded(true)
    }),
    setNotificationOptions: withActions(({setDialogOptions}, notificationType) => {
        if (notificationType) {
            setDialogOptions({notificationType, dialogType: DIALOGS.Notification})
        }
    }),
    reportSiteMembersBi: ({reportBI, biVisitorId}, biEventKey, params) => {
        const event = events[biEventKey]
        if (event && biVisitorId) { // 'visitorId' is empty in preview mode
            reportBI(event, _.merge(params || {}, {visitor_id: biVisitorId}))
        }
    },
    customNavigation: withActions(({setDialogOptions}, params, {navigateToPage, dialogOptions}) => {
        if (params) {
            navigateToPage(params)
            if (params.isPopup) {//We need to clear the dialogType in signup for the case of reopening the dialog
                const newDialogOptions = Object.assign(dialogOptions, {dialogType: null})
                setDialogOptions(newDialogOptions)
            }
        }
    }),
    onSubmitCallback: () => {
        //Waiting for TPA
        console.log('hhh')
    },
    hasSocialAppInstalled: configMap => _.some(_.map(configMap, x => JSON.parse(x.config)), x => x.type === 'public' && x.patterns && Object.keys(x.patterns).length > 0),
    getLangFromCookie: smCookie => {
        const smCookieLang = smCookie && smCookie.split('|')[1]
        return smCookieLang && smCookieLang.trim() // eslsint-disable-line no-mixed-operators
    },
    ...siteMembersApis,
    ...dialogProps
}

export function init() {
}
