import _ from 'lodash'
const {withActions} = require('carmi-host-extensions')

export const name = 'TPAWidgetNativeAspect'
export const functionLibrary = {
    capitalize: v => _.capitalize(v),
    setReactComponent: withActions(({setNativeComponentData}, widgetId, obj) => {
        const reactComp = _.get(obj, 'default.component')
        setNativeComponentData(widgetId, {component: reactComp})
    }),
    getEditorIndex: colorName => Number(colorName.split('_').pop()),

    awaitForFontsToLoad: withActions(({
        spliceAwaitingFontFamilies
    }, loadedFonts, awaitingFontFamiliesLength, fontFamilies) =>
        new Promise(resolve => {
            spliceAwaitingFontFamilies(awaitingFontFamiliesLength, 0, {fontFamilies, resolve})
        })
    )
}

export const defaultModel = {
    data: {},
    props: {},
    awaitingFontFamilies: []
}

