import {withActions} from 'carmi-host-extensions/src/aspects/withActions'
import {urlUtils} from 'santa-core-utils'
import _ from 'lodash'

export const name = 'ClientSpecMapAspect'

export const defaultModel = {
    appInstanceMap: {},
    instanceChangedRegisteredComps: {},
    reloadSpecMapPlugins: []
}

const getDynamicApiUrl = (siteBaseUrl = '', windowObj, urlParams = {}) => {
    let url = `${siteBaseUrl.replace(/\/$/, '')}/_api/dynamicmodel`
    const params = _.isEmpty(urlParams) ? '' : `?${urlUtils.toQueryString(urlParams)}`
    if (windowObj) {
        url = url.replace(/^[^:]+:/, windowObj.location.protocol)
    }
    return url + params
}

const wixBiSessionPropertiesToUpdateOnReload = ['visitorId', 'siteMemberId']
const sessionInfoPropertiesToUpdateOnReload = ['svSession', 'hs', 'ctToken']

export const functionLibrary = {
    getDynamicApiUrl,
    reloadClientSpecMap: withActions((aspectActions, {
        fetchFn,
        externalBaseUrl,
        windowObj,
        reloadSpecMapPlugins,
        updateClientSpecMap,
        updateWixBiSessionProperty,
        updateSessionInfoProperty
    }, callback = _.noop, config = {}) => {
        const onSuccess = response => {
            _.invokeMap(reloadSpecMapPlugins, 'callback')
            _.forEach(wixBiSessionPropertiesToUpdateOnReload, prop => {
                if (response[prop]) {
                    updateWixBiSessionProperty(prop, response[prop])
                }
            })
            _.forEach(sessionInfoPropertiesToUpdateOnReload, prop => {
                if (response[prop]) {
                    updateSessionInfoProperty(prop, response[prop])
                }
            })
            if (response.clientSpecMap) {
                aspectActions.setInstanceMap({})
                updateClientSpecMap(response.clientSpecMap)
            }
            callback(response)
        }
        const url = getDynamicApiUrl(externalBaseUrl, windowObj, config.urlParams)
        fetchFn(url, null, 'json', onSuccess, callback)
    }),
    generateNewAppInstance: withActions((aspectActions, {fetchFn, url}, applicationId, callback) => {
        const onSuccess = response => {
            const instance = _.get(response, ['clientSpecMap', applicationId, 'instance'])
            aspectActions.setAppInstance(applicationId, instance)
            callback(instance)
        }
        fetchFn(url, null, 'json', onSuccess, () => {
            callback()
        })
    }),
    registerToInstanceChanged: withActions((aspectActions, instanceChangedRegisteredComps, comp) => {
        const applicationId = _.get(comp, 'props.compData.applicationId')
        const compId = _.get(comp, 'props.id')
        if (instanceChangedRegisteredComps[applicationId]) {
            aspectActions.setCompToInstanceChangedEvent(applicationId, compId, comp)
        } else {
            aspectActions.setAppInstanceChangedEvent(applicationId, {[compId]: comp})
        }
    }),
    unRegisterToInstanceChanged: withActions((aspectActions, instanceChangedRegisteredComps, comp) => {
        const applicationId = _.get(comp, 'props.compData.applicationId')
        const compId = _.get(comp, 'props.id')
        if (_.has(instanceChangedRegisteredComps, [applicationId, compId])) {
            aspectActions.setCompToInstanceChangedEvent(applicationId, compId, undefined)
        }
    }),
    registerReloadSpecMapPlugin: withActions((aspectActions, pluginsSize, getCurrentPrimaryPageIdFn, compPageId, callback) => {
        const plugin = {
            compPageId,
            callback: () => {
                const currentPageId = getCurrentPrimaryPageIdFn()
                if (currentPageId === compPageId) {
                    callback()
                }
            }
        }
        aspectActions.spliceReloadSpecMapPlugin(pluginsSize, 0, plugin)
    })
}
