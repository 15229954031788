import {withActions} from 'carmi-host-extensions/src/aspects/withActions'
import _ from 'lodash'

export const PUB_SUB_ASPECT_PREFIX = 'pubSub'
const TPA_PUB_SUB_PREFIX = 'TPA_PUB_SUB_'

export const getPubSubAspectKey = key => `${PUB_SUB_ASPECT_PREFIX}_${key}`

const getEmptyEventData = () => ({
    data: [],
    listeners: {}
})

const addSubscription = (pubSubActions, pubSubHub, appDefinitionId, eventName, compId, comp) => {
    if (!pubSubHub[appDefinitionId]) {
        pubSubActions.setAppData(appDefinitionId, {[eventName]: _.set(getEmptyEventData(), ['listeners', compId], comp)})
        return
    }
    if (!pubSubHub[appDefinitionId][eventName]) {
        pubSubActions.setAppEventData(appDefinitionId, eventName, _.set(getEmptyEventData(), ['listeners', compId], comp))
        return
    }
    if (!pubSubHub[appDefinitionId][eventName].listeners[compId]) {
        pubSubActions.setAppEventListener(appDefinitionId, eventName, compId, comp)
    }
}

const getPubSubAspectActions = aspectActions => _(aspectActions)
    .pickBy((val, key) => _.startsWith(key, PUB_SUB_ASPECT_PREFIX))
    .mapKeys((val, key) => key.replace(/^pubSub_/, ''))
    .value()

const pushEventData = (pubSubActions, appDefinitionId, eventName, appPubSubData, dataToPersist) => {
    pubSubActions.spliceEventData(appDefinitionId, eventName, appPubSubData[eventName].data.length, 0, dataToPersist)
}

const appendPrefix = eventKey => eventKey.indexOf(TPA_PUB_SUB_PREFIX) === 0 ? eventKey : TPA_PUB_SUB_PREFIX.concat(eventKey)

export const pubSubFunctionLibrary = _.mapKeys({
    stripPubSubPrefix: eventKey => eventKey.replace(/^TPA_PUB_SUB_/, ''),
    subscribe: withActions((aspectActions, message, {eventName, appDefinitionId}, pubSubHub, comp, isMessageHandled) => {
        if (!isMessageHandled) {
            const pubSubActions = getPubSubAspectActions(aspectActions)
            addSubscription(pubSubActions, pubSubHub, appDefinitionId, eventName, message.compId, comp)
        }
    }),
    publish: withActions((aspectActions, eventListeners, message, eventName, isPersistent, isMessageHandled) => {
        if (!isMessageHandled) {
            const dataToPublish = {
                eventType: appendPrefix(message.data.eventKey),
                intent: 'addEventListener',
                params: {
                    data: message.data.eventData,
                    name: eventName,
                    origin: message.compId
                }
            }
            _.forEach(eventListeners, comp => comp.sendPostMessage(dataToPublish))
            if (!isPersistent) {
                getPubSubAspectActions(aspectActions).setMessageHandled(message.id, true)
            }
        }
    }),
    persistEvent: withActions((aspectActions, appDefinitionId, eventName, eventData, appPubSubData, isMessageHandled, compId, messageId) => {
        if (!isMessageHandled) {
            const pubSubActions = getPubSubAspectActions(aspectActions)
            const dataToPersist = {
                data: eventData,
                name: eventName,
                origin: compId
            }
            if (!appPubSubData) {
                pubSubActions.setAppData(appDefinitionId, {[eventName]: _.set(getEmptyEventData(), 'data', [dataToPersist])})
            } else if (!appPubSubData[eventName]) {
                pubSubActions.setAppEventData(appDefinitionId, eventName, _.set(getEmptyEventData(), 'data', [dataToPersist]))
            } else {
                pushEventData(pubSubActions, appDefinitionId, eventName, appPubSubData, dataToPersist)
            }
            pubSubActions.setMessageHandled(messageId, true)
        }
    }),
    unsubscribe: withActions((aspectActions, appDefinitionId, eventName, compId, appPubSubData, isMessageHandled, messageId) => {
        if (!isMessageHandled) {
            const pubSubActions = getPubSubAspectActions(aspectActions)
            if (appPubSubData) {
                const appEventData = appPubSubData[eventName]
                if (appEventData) {
                    pubSubActions.setAppData(appDefinitionId, _.set(appPubSubData, [eventName], {
                        ...appEventData,
                        listeners: _.omit(appEventData.listeners, [compId])
                    }))
                }
            }
            pubSubActions.setMessageHandled(messageId, true)
        }
    }),
    deleteCompListeners: withActions((aspectActions, pubSubHub, appDefinitionId, compId) => {
        const appData = pubSubHub[appDefinitionId]
        const pubSubActions = getPubSubAspectActions(aspectActions)
        if (appData) {
            pubSubActions.setAppData(appDefinitionId, _.mapValues(appData, ({data, listeners}) => ({
                data,
                listeners: _.omit(listeners, compId)
            })))
        }
    }),
    markMessageHandled: withActions((aspectActions, messageId) => {
        getPubSubAspectActions(aspectActions).setMessageHandled(messageId, true)
    })
}, (value, key) => getPubSubAspectKey(key))
