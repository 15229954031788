import _ from 'lodash'
import {utils} from 'santa-renderer'
import {exetendWithQaInfo} from './components/qaHOC' // TODO: rename file
import {BoltRuntimes} from './client/BoltRuntimes'
import EventEmitter from 'events'

const {hostRuntimeUtils} = utils

const boltInit = (modelFactory, model, renderingFunctionsLib, hostApi, logger, functionLibraryOverrides) => {
    const eventsManager = new EventEmitter()
    const boltFunctionLib = hostRuntimeUtils.buildFuncLib(eventsManager, BoltRuntimes, {}, renderingFunctionsLib, hostApi, functionLibraryOverrides)

    const boltDefaultModel = hostRuntimeUtils.buildDefaultModel(BoltRuntimes)

    const shouldExtendWithQAInfo = typeof window !== 'undefined' && _.get(model, ['currentUrl', 'query', 'isqa']) === 'true'
    const wrapper = shouldExtendWithQAInfo ? exetendWithQaInfo : _.identity

    const instance = modelFactory(_.merge({}, boltDefaultModel, model), {...boltFunctionLib, wrapper})

    return hostRuntimeUtils.initInstance(instance, eventsManager, BoltRuntimes, logger)
}

const runtimeModulesForValidation = [{functionLibrary: hostRuntimeUtils.buildFuncLib(new EventEmitter())}, ...BoltRuntimes]

export {
    boltInit,

    // TODO remove when we have namespaces
    runtimeModulesForValidation
}
