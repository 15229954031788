import _ from 'lodash'
import {NULL_RETURN_VALUE} from 'bolt-tpa' //TODO: extract constants to some place which isn't tps package

export {apiCallsHandlerName as name} from '../platform.schema'

export const defaultModel = {
    apiCalls: {}
}

export function getResponseMessage(originalMessage, data, errorMessage) {
    return {
        callbackId: originalMessage.callbackId,
        contextId: originalMessage.contextId,
        error: errorMessage,
        intent: 'WIX_CODE_RESPONSE',
        result: data,
        type: originalMessage.type
    }
}

export const functionLibrary = {
    sendApiCallResult: (mayBeAsyncResult, apiCallMessage, setApiCallMessage, wixCodeAppApi, pagesToContexts) => {
        const {__uniqueId} = apiCallMessage
        const contextPageId = apiCallMessage.contextId || apiCallMessage.workerId
        const contextId = pagesToContexts[contextPageId]

        if (_.isNil(mayBeAsyncResult) ||
            mayBeAsyncResult && !mayBeAsyncResult.waitForPromiseToBeResolved) {
            // Setting the message with undefined will delete the key from the events object
            setApiCallMessage(__uniqueId, undefined)
        }

        mayBeAsyncResult = mayBeAsyncResult && mayBeAsyncResult.waitForPromiseToBeResolved ? mayBeAsyncResult.promise : mayBeAsyncResult

        return Promise.resolve(mayBeAsyncResult)
            .then(result => {
                if (_.isNil(result)) {
                    return
                }
                const finalResult = result === NULL_RETURN_VALUE ? null : result

                wixCodeAppApi[contextId].sendMessage(getResponseMessage(apiCallMessage, finalResult))
                // need this here in case waitForPromiseToBeResolved was defined
                setApiCallMessage(__uniqueId, undefined)
            })
            .catch(err => {
                const errorMessage = _.isObject(err) ? err.message : err
                wixCodeAppApi[contextId].sendMessage(getResponseMessage(apiCallMessage, null, errorMessage))
            })
    }
}
