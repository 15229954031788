import {withActions} from '../withActions'
import {throttleHandler} from './resizeUtils'
export const name = 'ScreenDimensionsAspect'

export const defaultModel = {
    dimensions: {
        documentElementDimensions: {width: 0, height: 0},
        windowSize: {width: 0, height: 0}
    },
    compsToNotify: {
        orientation: {},
        resize: {}
    }
}

export const functionLibrary = {
    updateScreenSize: withActions(({setScreenDimensions}, documentElement) => {
        if (!documentElement) {
            return
        }

        setScreenDimensions({
            windowSize: {
                width: documentElement.clientWidth,
                height: documentElement.clientHeight
            }
        })
    }),
    getCurrentTime: () => new Date().getTime(),
    removeCompToNotifyOnEvent: removeFn => { removeFn() }
}

export function init({}, {eventsManager, initialData: {updateScreenSize}}) {
    eventsManager.on('windowResize', throttleHandler(updateScreenSize))

    updateScreenSize()
}
