const _ = require('lodash')
const invokeTrackEvent = require('./promoteAnalytics')
const thirdPartyAnalytics = require('thirdPartyAnalytics')

const name = 'AnalyticsAspect'

const defaultModel = {
    promoteAnalyticsAdapter: null
}

const trackEvent = (analyticsProps, packageProps, eventName, params, options) => {
    if (!packageProps.promoteAnalyticsAdapter) {
        return new Promise(resolve => {
            packageProps.requireFn('promote-analytics-adapter', analyticsPackage => {
                packageProps.setAnalyticsAdapterPackage(analyticsPackage)
                resolve(invokeTrackEvent(analyticsProps, eventName, params, options, analyticsPackage))
            })
        })
    }
    return Promise.resolve(invokeTrackEvent(analyticsProps, eventName, params, options, packageProps.promoteAnalyticsAdapter))
}

const getPagePath = (currentUrl, externalBaseUrl) => {
    const currentUrlString = _.isString(currentUrl) ? currentUrl : currentUrl.full || ''
    const baseUrl = externalBaseUrl.replace(/\/$/, '')
    return currentUrlString.replace(baseUrl, '')
}

const reportPageEvent = (analyticsProps, packageProps) => {
    const pageData = {
        pagePath: getPagePath(analyticsProps.currentUrl, analyticsProps.externalBaseUrl),
        pageTitle: analyticsProps.pageTitle
    }
    thirdPartyAnalytics.reportPageEvent(analyticsProps, pageData.pagePath)
    trackEvent(analyticsProps, packageProps, 'PageView', {}, pageData)
}

const initFacebookRemarketing = (analyticsProps, pixelId) => pixelId ? thirdPartyAnalytics.initFacebookRemarketingPixelId(analyticsProps, pixelId) : thirdPartyAnalytics.initFacebookRemarketingUserPixel(analyticsProps)
const initGoogleRemarketing = analyticsProps => thirdPartyAnalytics.initGoogleRemarketingPixel(analyticsProps)
const initYandexMetrika = analyticsProps => thirdPartyAnalytics.initYandexMetrika(analyticsProps)

const functionLibrary = {
    fireFacebookRemarketingPixel: (analyticsProps, eventName, eventData) => thirdPartyAnalytics.fireFacebookRemarketingPixel(analyticsProps, eventName, eventData),
    fireFacebookCustomEvent: (analyticsProps, eventType, eventName, data) => thirdPartyAnalytics.fireFacebookCustomEvent(analyticsProps, eventType, eventName, data),
    fireGoogleRemarketing: () => thirdPartyAnalytics.fireGoogleRemarketingPixel(),
    reportGoogleAnalytics: (analyticsProps, eventParams) => thirdPartyAnalytics.reportGoogleAnalytics(analyticsProps, eventParams),
    reportGoogleTagManager: (analyticsProps, eventParams) => thirdPartyAnalytics.reportGoogleTagManager(analyticsProps, eventParams),
    reportBiAnalytics: (analyticsProps, eventParams) => thirdPartyAnalytics.reportBiAnalytics(analyticsProps, eventParams),
    reportYandexPageHit: url => thirdPartyAnalytics.reportYandexPageHit(url),
    reportPageEvent,
    trackEvent
}

function init({}, {initialData: {analyticsProps, isInSSR}}) {
    if (isInSSR) {
        return
    }
    initFacebookRemarketing(analyticsProps)
    initGoogleRemarketing(analyticsProps)
    initYandexMetrika(analyticsProps)
}

module.exports = {
    name,
    defaultModel,
    functionLibrary,
    init
}
