import {withActions} from '../withActions'
import * as constants from 'santa-renderer/src/utils/constants'
import _ from 'lodash'
import reactDOM from 'react-dom'

const layoutUtilsFactory = require('carmi-host-extensions/src/layout/layoutUtils')

const name = 'LayoutAspect'

let layoutUtils // eslint-disable-line santa/no-module-state
let compRefs // eslint-disable-line santa/no-module-state
let _logger // eslint-disable-line santa/no-module-state

const defaultModel = {
    pendingReLayoutComps: {},
    measureMap: {},
    layoutCounter: 0,
    isLayoutUtilsInitialized: false,
    scheduledLayoutPromise: null,
    fontLoaded: false
}

function init(aspectActions, {eventsManager, initialData, logger}) {
    _logger = logger
    compRefs = initialData.compRefs

    if (!initialData.isInSSR) {
        eventsManager.on(constants.EVENTS.FONT_LOAD, () => aspectActions.setFontLoaded(true))
    }
}

function triggerComponentDidLayoutRecursive(comp) {
    if (comp.componentDidLayout) {
        comp.componentDidLayout.call(comp)
    }
    if (comp.componentDidLayoutAnimations) {
        comp.componentDidLayoutAnimations.call(comp)
    }
    _.forOwn(comp.refs, triggerComponentDidLayoutRecursive)
}

const functionLibrary = {
    getLayoutMechanism: (requireFn, currentUrl, publicModel, browserFlags, masterPageLayoutSettings) => {
        const layoutApi = {
            getRequestedLayoutMechanism: () => _.get(currentUrl, ['query', 'layoutMechanism']),
            browserFlags: () => browserFlags,
            getMasterPageLayoutSettings: () => masterPageLayoutSettings,
            getSiteMeshReadyFallbackFlag: () => _.get(publicModel, 'siteMeshReady', 'anchors')
        }
        return requireFn('warmupUtilsLib').layoutUtils.getLayoutMechanism(layoutApi, _.constant(true))
    },
    generateAnchors: ({requireFn, pageComps, pageId, flags, siteTheme, componentsTemplateId, viewMode, shouldApplyMobileTightLayout}) => {
        const coreUtils = requireFn('coreUtils')
        const deepStructure = coreUtils.flatStructureUtil.buildDeepStructure(pageComps[pageId], pageComps)
        if (pageId === 'masterPage' && flags.forceMobileStructure && shouldApplyMobileTightLayout) {
            deepStructure.mobileComponents = deepStructure.children
            return {
                [viewMode]: coreUtils.layoutAnchors.createMobileTightMasterPageAnchors(deepStructure, siteTheme, flags)
            }
        }
        return {
            [viewMode]: coreUtils.layoutAnchors.createPageAnchors(deepStructure, siteTheme, flags, componentsTemplateId)
        }
    },
    isMasterPagesSectionsTight(masterPageSectionLayouts) {
        for (let i = 0; i < masterPageSectionLayouts.length - 1; i++) {
            const currentSection = masterPageSectionLayouts[i]
            const nextSection = masterPageSectionLayouts[i + 1]
            const currentSectionBottom = currentSection.y + currentSection.height
            const nextSectionTop = nextSection.y
            if (currentSectionBottom !== nextSectionTop) {
                return false
            }
        }
        return true
    },
    registerLayoutFuncForNode: (domNode, func) => {
        layoutUtils.registerLayoutFunc(domNode, func)
    },
    registerReLayoutPendingForComp: withActions((aspectActions, compId) => {
        aspectActions.markPendingReLayout(compId, true)
    }),
    runLayout: withActions((aspectActions, checkCanRunLayout, getCurrentLayoutData, getLayoutCounter) => {
        if (!checkCanRunLayout) {
            aspectActions.setScheduledLayoutPromise(null)
            return
        }
        aspectActions.setPendingReLayoutComps({})
        aspectActions.setFontLoaded(false)
        const {measureMap} = layoutUtils.runLayout({...getCurrentLayoutData(), compRefs})
        aspectActions.setMeasureMap(measureMap)
        aspectActions.setLayoutCounter(getLayoutCounter() + 1)
        aspectActions.setScheduledLayoutPromise(null)
    }),
    triggerLayout: withActions(({setScheduledLayoutPromise}, runLayout) => {
        if (runLayout) {
            setScheduledLayoutPromise(Promise.resolve().then(runLayout))
        }
    }),
    initLayoutUtils: withActions((aspectActions, fullLayoutData) => {
        const warmupUtils = require('warmupUtils')
        const layout = require('layout')

        layoutUtils = layoutUtilsFactory.init(fullLayoutData, {warmupUtils, layout, lodash: _, reactDOM}, false, _logger)
        aspectActions.setIsLayoutUtilsInitialized(true)
    }),
    triggerComponentDidLayout: layoutCounter => {
        if (layoutCounter > 0) {
            _.forEach(compRefs, triggerComponentDidLayoutRecursive)
        }
    },
    showPage({layoutCounter, compRef}) {
        if (layoutCounter && compRef) {
            reactDOM.findDOMNode(compRef).style.visibility = '' // eslint-disable-line react/no-find-dom-node
        }
    },
    removeImage: id => layoutUtils.imageLoader.removeImage(id),
    getCustomMeasureMap: (customMeasureMap, customId) => _.get(customMeasureMap, customId)
}

export {
    name,
    defaultModel,
    init,
    functionLibrary
}
