import {withActions} from 'carmi-host-extensions'


export const name = 'PageTransitionAspect'

export const defaultModel = {}

const setElementStyle = (document, elementRef, attribute, value) => {
    const element = document.getElementById(elementRef)
    if (element) {
        element.style[attribute] = value
    }
}

const setPageOverflow = (document, pageRef, displayValue) => {
    setElementStyle(document, pageRef, 'overflow', displayValue)
}

const setPageMaxHeight = (document, pageRef, displayValue) => {
    setElementStyle(document, pageRef, 'maxHeight', displayValue)
}

const setPageVisibility = (document, pageRef, visibilityValue) => {
    setElementStyle(document, pageRef, 'visibility', visibilityValue)
}

const createPageTransition = (pageTransition, animator, duration, document) => {
    const {callbacks, previousRef, currentRef, delay, params, transitionName} = pageTransition
    const oldPageElement = document.getElementById(previousRef)
    const newPageElement = document.getElementById(currentRef)
    if (!oldPageElement || !newPageElement) {
        return
    }
    animator.sequence({callbacks})
        .add(animator.transition(transitionName, oldPageElement, newPageElement, duration, delay, {...params}))
        .add(animator.animate('BaseClear', [oldPageElement, newPageElement], 0, 0, {
            props: 'opacity,x,y',
            immediateRender: false
        }))
}

const createBackgroundTransition = (bgTransition, animator, duration, transitionName, params, document) => {
    if (bgTransition) {
        const {delay, callbacks} = bgTransition
        const oldBgElement = document.querySelector('[id^="SITE_BACKGROUND_previous"]')
        const newBgElement = document.querySelector('[id^="SITE_BACKGROUND_current"]')
        animator.transition(transitionName, oldBgElement, newBgElement, duration, delay, {callbacks, ...params})
    }
}

export const functionLibrary = {
    registerBackgroundTransition: withActions(({setPageTransitionParams}, comp, previousRef, currentRef, callbacks) => {
        setPageTransitionParams('background', {
            comp,
            previousRef,
            currentRef,
            callbacks
        })
    }),

    registerPageTransition: withActions(({setStartTransition}, isDuringPageTransition) => {
        setStartTransition('startTransition', isDuringPageTransition)
    }),

    handlePageTransition: withActions((actions, shouldStartTransition, getTransitionParams) => {
        if (shouldStartTransition) {
            const {windowObject, duration, pageTransition, /*pageScrollData,*/ bgTransition, animator} = getTransitionParams()
            const {params, transitionName} = pageTransition
            const {document} = windowObject
            setPageMaxHeight(document, pageTransition.currentRef, '')
            setPageOverflow(document, pageTransition.currentRef, '')
            setPageVisibility(document, pageTransition.currentRef, 'visible')

            // TODO BOLT-515 Once page transition (out) is implemented this should be revisited
            setPageMaxHeight(document, pageTransition.previousRef, '0px')

            createPageTransition(pageTransition, animator, duration, document)
            createBackgroundTransition(bgTransition, animator, duration, transitionName, params, document)

            //TODO: check if to animate scroll to anchor
            actions.setPageTransitionParams('background')
            actions.setStartTransition('startTransition', false)
        }
    })
}
