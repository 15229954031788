import React from 'react'
import ReactDOM from 'react-dom'
import {Renderer} from 'santa-renderer'
import modelFactory from './client/bolt.carmi'
// const {isWithoutDiverges} = require('./ssr/divergesCheck')
import {boltInit} from './bolt-runtime'
const {getFunctionsLibrary} = require('carmi-react')

const createBoltSiteElement = boltInstance => <Renderer instance={boltInstance} />

const markRenderDone = measureName => {
    console.log(`finished ${measureName}`, performance.now())
    performance.mark(`${measureName} complete`)
}

const renderClientSide = (boltInstance, rootElement, hydrate, serverMarkup, indicator) => {
    const boltSiteElement = createBoltSiteElement(boltInstance)

    boltInstance.$startBatch()
    if (hydrate) {
        ReactDOM.hydrate(boltSiteElement, rootElement,
            () => {
                markRenderDone('hydrate')
                boltInstance.setIsFirstRenderAfterSSR(false)
                // console.log(isWithoutDiverges(serverMarkup, clientMarkup))
                window.sssr.success = true
                indicator.updateState(indicator.STATES.SUCCESS)
                boltInstance.$endBatch()
            })
    } else {
        ReactDOM.render(boltSiteElement, rootElement,
            () => {
                markRenderDone('render')
                boltInstance.$endBatch()
            })
    }
}

const createBoltInstance = (siteModel, renderingPlugins, hostApi = {}, logger, functionLibraryOverrides = {}) =>
    boltInit(modelFactory, siteModel, getFunctionsLibrary(renderingPlugins), hostApi, logger, functionLibraryOverrides)

export default {
    createBoltSiteElement,
    renderClientSide,
    createBoltInstance
}
