import _ from 'lodash'
import {urlUtils} from 'santa-core-utils'

const addAppSectionParamsToNavInfo = (navInfo, appSectionParams = {}) => {
    if (!_.isEmpty(appSectionParams)) {
        try {
            navInfo.queryParams = {appSectionParams: JSON.stringify(appSectionParams)}
            navInfo.hasAppSectionParams = true
        } catch (ex) { // bummer
        }
    }

    return navInfo
}

export const navigationHandlersFunctionLibrary = {
    toQueryString: urlUtils.toQueryString,
    addAppSectionParamsToNavInfo,
    buildNavigateToSectionPageNavInfo(pageId, state, appSectionParams, noTransition) {
        const navInfo = addAppSectionParamsToNavInfo({
            pageId,
            tpaInnerRoute: state
        }, appSectionParams)

        if (noTransition) {
            navInfo.transition = 'none'
        }

        return navInfo
    },
    setSectionStateAndQueryParams(
        sectionReactComp,
        shouldRefreshIframe,
        sectionCompId,
        state,
        appSectionParams
    ) {
        if (sectionCompId && sectionReactComp) {
            if (shouldRefreshIframe) {
                sectionReactComp.setState({
                    sectionUrlState: state
                })
                sectionReactComp.setState({
                    sectionUrlParams: appSectionParams
                })
            } else {
                // no solution implemented for queryParams
                sectionReactComp.setState({
                    pushState: state
                })
                sectionReactComp.reportStateChanged(state)
            }
        }
    },
    navigateWithoutClosingPopupIfPossible(
        navInfo,
        primaryPageId,
        onComplete,
        navigateToPage,
        scrollSiteTo
    ) {
        if (navInfo.pageId === primaryPageId) {
            navigateToPage(navInfo)
            scrollSiteTo(0, 0)
        } else {
            navigateToPage(navInfo)
        }

        if (_.isFunction(onComplete)) {
            onComplete()
        }
    }
}
