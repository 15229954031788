
export {name} from './platform.schema'

export const defaultModel = {
    __runtime: null,
    inited: {}
}

export const functionLibrary = {
    initWixCodeAPI: (api, contextId, messageHandler, setInited, isInited) => {
        if (isInited()) {
            return
        }
        api.listen((...args) => {
            messageHandler(contextId, ...args)                
        })
        setInited()
    },

    deleteInited: (cId, setInited) => {
        setInited(cId, undefined)
    },

    setPendingRuntimeToModel(setRuntime) {
        setRuntime()
        return true
    }
}
