import _ from 'lodash'
import {resolveData} from '../services/resolveData'

const overrideTypeToRuntimeDalMethod = {
    data: 'setCompData',
    props: 'setCompProps',
    layout: 'setLayout',
    design: 'setDesign',
    style: 'setStyle'
}

export const handleBatchCommand = (runtimeDal, batchData, resolverGeneralInfo) => {
    _.forOwn(batchData, (overridesByType, compId) => {
        _.forOwn(overridesByType, (overrides, overrideType) => {
            if (overrideType === 'registerEvent') {
                _.forEach(overrides, componentEvent => {
                    runtimeDal.registerComponentEvent(compId, componentEvent)
                })
                return
            }

            const resolvedOverrides = overrideType === 'data' ? 
                resolveData(runtimeDal, compId, overrides, resolverGeneralInfo) :
                overrides

            runtimeDal[overrideTypeToRuntimeDalMethod[overrideType]](compId, resolvedOverrides)
        })
    })
}