'use strict'
const _ = require('lodash')

const rendererFunctionLibrary = require('../aspects/ComponentsModelAspect/carmi/functionLibrary')

const actionBehaviorsExtension = require('../aspects/BehaviorsAspect/actionBehaviorsExtension')
const runtimeDalExtension = require('../aspects/ComponentsModelAspect/runtimeDal/runtimeDALExtension')
const componentsRenderExtension = require('../aspects/ComponentsRenderAspect/componentsRenderExtension')
const rendererRuntimes = [runtimeDalExtension, actionBehaviorsExtension, componentsRenderExtension]

const buildDefaultFunclib = (eventsManager, schemes, renderingFunctionsLib, hostApiFunctionLib) => _.assign(rendererFunctionLibrary.create(eventsManager, schemes), renderingFunctionsLib, hostApiFunctionLib)

const buildFuncLib = (eventsManager, runtimeModules, schemes = {}, renderingFunctionsLib, hostApiFunctionLib, functionLibraryOverrides) =>
    _.chain(runtimeModules)
        .filter('functionLibrary')
        .reduce((acc, {functionLibrary}) => _.assign(acc, functionLibrary), buildDefaultFunclib(eventsManager, schemes, renderingFunctionsLib, hostApiFunctionLib))
        .assign(functionLibraryOverrides)
        .value()


const buildDefaultModel = runtimeModules => {
    const allModules = rendererRuntimes.concat(runtimeModules)
    return _(allModules).filter('defaultModel').reduce((acc, {name, defaultModel}) => _.assign(acc, {[name]: defaultModel}), {})
}
const initInstance = (carmiInstance, eventsManager, runtimeModules, logger) => {
    const allModules = rendererRuntimes.concat(runtimeModules)
    _(allModules).filter('init').forEach(({init, name}) => init(carmiInstance, {eventsManager, initialData: carmiInstance.initMethodsData[name], logger}))

    return carmiInstance
}

module.exports = {
    buildFuncLib,
    buildDefaultModel,
    initInstance
}
