import events from '../events.json'
import {APPS, COOKIES, DIALOGS, NOTIFICATIONS} from '../constants'
import {withActions} from 'carmi-host-extensions/src/aspects/withActions'
import _ from 'lodash'

const SIGNED_IN_PATTERN = '_signedInUser_'
const SECTION = {MEMBER_INFO: 'member_info'}

export const getAppAPI = () => {
}

const showNotification = ({setDialogOptions}, notificationType, languageToDisplay) => {
    setDialogOptions({
        dialogType: DIALOGS.Notification,
        notificationType,
        ...languageToDisplay ? {languageToDisplay} : {}
    })
}

const revokeSocialAppPermission = (contentWindow, type) => {
    contentWindow.postMessage({
        src: 'wix-social-login',
        type,
        action: 'revoke'
    }, '*')
}

const onDialogError = (dialog, errorCode, isOAuth = false) => {
    dialog.endLoader()
    if (isOAuth) {
        dialog.setOuathErrorMessageByCode(errorCode)
    } else {
        dialog.setErrorMessageByCode(errorCode)
    }
}

const onDialogSuccess = ({setDialogOptions}, dialog, dialogOptions, member) => {
    if (dialogOptions && dialogOptions.successCallback) {
        dialogOptions.successCallback(member)
    }
    dialog.endLoader()
    setDialogOptions(null)
}

export default {
    onSubmitCallback: () => {
        //Waiting for TPA
        console.log('hhh')
    },
    closeDialog: withActions(({setDialogOptions}, {completeWithNavigation, resetNextPageNavigationInfo, reportSmBi, dialogOptions}, dialog, authSuccess, isUserAction) => {
        if (isUserAction) {
            reportSmBi(events.SITE_MEMBER_EXIT_DIALOG, {context: dialog.props.biContext || dialog.props.id})
        }
        if (dialogOptions && dialogOptions.cancelCallback) {
            dialogOptions.cancelCallback()
        }
        setDialogOptions(null)
        resetNextPageNavigationInfo()
        completeWithNavigation()
    }),
    closeWelcomeDialog: withActions((context, {closeDialog, mainPagePath, deleteCookieFn, hostName}, ...args) => {
        closeDialog(...args)
        deleteCookieFn({name: COOKIES.SM_EF, domain: hostName, path: mainPagePath})
        deleteCookieFn({name: COOKIES.SM_EF, domain: hostName, path: '/'})
    }),
    handleRegister: ({handleRegisterSuccess, collectionId, metaSiteId, appUrl, svSession, register}, registerData, onSuccess, onError) => {
        const data = {
            method: 'POST',
            body: {
                svSession,
                collectionId,
                metaSiteId,
                appUrl,
                ...registerData
            }
        }

        register(data, ({payload: {siteMemberDto: member, sessionToken, emailVerified, status: memberStatus, email}}) => {
            handleRegisterSuccess({
                emailVerified,
                member,
                email,
                memberStatus,
                sessionToken,
                languageToDisplay: registerData.lang
            }, onSuccess, onError)
        }, onError)
    },
    handleToken: ({handleRegisterSuccess, svSession, biVisitorId, handleOauthToken}, submitData, postMessageTarget, onSuccess, onError) => {
        const data = {
            method: 'POST',
            body: {
                svSession,
                visitorId: biVisitorId,
                ...submitData
            }
        }

        revokeSocialAppPermission(postMessageTarget, submitData.provider)
        handleOauthToken(data, ({payload: {siteMemberDto: member, smSession}}) => {
            const sessionToken = smSession && smSession.sessionToken
            handleRegisterSuccess({
                emailVerified: true,
                member,
                memberStatus: member.status,
                email: member.email,
                sessionToken,
                languageToDisplay: submitData.lang
            }, onSuccess, onError)
        }, onError)
    },
    handleWelcomeSubmit: (data, dialog) => {
        // Todo: implement getAppApi by TPA. Blocker
        const msg = {
            appDefId: APPS.MEMBER_AREA,
            workerId: this.siteData.getPrimaryPageId()
        }
        dialog.closeDialog(false)
        getAppAPI.call(this, this.aspectSiteApi.getSiteAPI(), msg)
            .then(api =>
                api.navigateToSection({
                    appDefinitionId: APPS.MEMBER_INFO,
                    sectionId: SECTION.MEMBER_INFO,
                    memberId: SIGNED_IN_PATTERN
                }))
    },
    changePasswordHandler: ({resetMemberPassword, onPasswordChangeSuccess, reportSmBi}, submitData, dialog) => {
        reportSmBi('SITE_MEMBER_SUBMIT_BUTTON', {context: dialog.props.id})
        const data = {
            method: 'POST',
            body: submitData
        }
        resetMemberPassword(data, () => onPasswordChangeSuccess(), dialog.setErrorMessageByCode)
    },
    onPasswordChangeSuccess: withActions((actions, {language, navigationInfo, navigateToPage}) => {
        const {pageId, queryParams} = navigationInfo
        const newQueryParams = _.clone(queryParams)
        delete newQueryParams.forgotPasswordToken
        navigateToPage({pageId, queryParams})
        showNotification(actions, NOTIFICATIONS.ResetPasswordNewPassword, language)
    }),
    onRegisterCallback: withActions(({setDialogOptions, setMemberDetails}, {handleRegister, dialogOptions, reportSmBi}, registerData, dialog) => {
        reportSmBi(events.SITE_MEMBER_SUBMIT_BUTTON, {context: dialog.props.id})
        dialog.startLoader()
        const onError = errorCode => onDialogError(dialog, errorCode)

        const onSuccess = member => {
            setMemberDetails(member)
            onDialogSuccess({setDialogOptions}, dialog, dialogOptions, member)
        }
        const dialogData = {
            lang: dialog.props.language,
            privacyStatus: dialog.getJoinCommunityStatus()
        }
        handleRegister({...registerData, ...dialogData}, onSuccess, onError)
    }),
    tokenHandler: withActions(({setDialogOptions, setMemberDetails}, {handleToken, dialogOptions}, token, provider, postMessageTarget, dialog) => {
        dialog.startLoader()
        const onError = errorCode => onDialogError(dialog, errorCode, true)

        const onSuccess = member => {
            setMemberDetails(member)
            onDialogSuccess({setDialogOptions}, dialog, dialogOptions, member)
        }

        const submitData = {
            token,
            provider,
            mode: dialog.props.id,
            lang: dialog.props.language,
            privacyStatus: dialog.getJoinCommunityStatus()
        }
        handleToken(submitData, postMessageTarget, onSuccess, onError)
    }),
    onLoginCallback: withActions(({setDialogOptions}, {handleLogin, memberLoginCallbacks, dialogOptions, reportSmBi}, loginData, dialog) => {
        const onError = errorCode => onDialogError(dialog, errorCode)
        const onSuccess = member => {
            memberLoginCallbacks.forEach(cb => cb())
            onDialogSuccess({setDialogOptions}, dialog, dialogOptions, member)
        }

        reportSmBi(events.SITE_MEMBER_SUBMIT_BUTTON, {context: dialog.props.id})
        dialog.startLoader()
        handleLogin(loginData, ({member}) => onSuccess(member), onError)
    }),
    forgotPasswordMailHandler: withActions((context, {collectionId, dialogOptions, metaSiteId, appUrl, sendForgotPasswordMail}, submitData, dialog) => {
        const data = {
            method: 'POST',
            body: {
                returnUrl: appUrl,
                collectionId,
                metaSiteId,
                lang: dialog.props.language,
                email: submitData.email
            }
        }
        const onSuccess = () => {
            if (dialogOptions && dialogOptions.successCallback) {
                dialogOptions.successCallback()
            }
            showNotification(context, NOTIFICATIONS.ResetPasswordEmail, dialog.props.language)
        }
        sendForgotPasswordMail(data, onSuccess, dialog.setErrorMessageByCode)
    }),
    passwordProtectedServerHandler: withActions(({setPageJsonFileName}, {approvePasswordProtectedPage, validatePassword, metaSiteId, siteId, dialogOptions}, submitData, dialog) => {
        if (_.isEmpty(submitData.password)) {
            dialog.setErrorMessage('PasswordLogin_Wrong_Password')
            return
        }

        const onSuccess = ({payload}) => {
            if (payload.urls[0]) {
                const pageJsonFileName = _.last(payload.urls[0].split('.json')[0].split('\/'))
                setPageJsonFileName(dialogOptions.nextPageId, `${pageJsonFileName}.json`)
            }
            approvePasswordProtectedPage()
            dialog.endLoader()
        }

        const onError = errorCode => {
            dialog.endLoader()
            dialog.setErrorMessageByCode(errorCode || 'SMForm_Error_General_Err')
        }

        const data = {
            method: 'POST',
            body: {
                password: submitData.password,
                metaSiteId,
                siteId,
                pageId: dialogOptions.nextPageId
            }
        }
        validatePassword(data, onSuccess, onError)
    }),
    passwordProtectedClientHandler: ({approvePasswordProtectedPage, pageSecurityData}, submitData, dialog) => {
        if (_.isEmpty(submitData.password)) {
            dialog.setErrorMessage('PasswordLogin_Wrong_Password')
        } else if (pageSecurityData.passwordDigest === submitData.password) {
            dialog.endLoader()
            approvePasswordProtectedPage()
        } else {
            dialog.endLoader()
            dialog.setErrorMessageByCode('SMForm_Error_General_Err')
        }
    },
    approvePasswordProtectedPage: withActions(({setDialogOptions, setApprovedPasswordPage}, {navigateToPage, dialogOptions}) => {
        setDialogOptions(null)
        if (dialogOptions && dialogOptions.nextPageId) {
            setApprovedPasswordPage(dialogOptions.nextPageId, true)
            navigateToPage({pageId: dialogOptions.nextPageId})
        }
    }),
    resendEmailVerification: withActions(({switchDialogType}, {reportSmBi, biContext, resendEmailVerification}) => {
        reportSmBi(events.SITE_MEMBER_CLICK_LINK, {context: biContext, name: 'resend confirmation email'})
        resendEmailVerification()
        switchDialogType(DIALOGS.SentConfirmationEmail)
    }),
    onCloseSuccessNotification: withActions(({setDialogOptions}, {reportSmBi}) => {
        const notificationId = 'resetPasswordSuccessNotification'
        setDialogOptions({dialogType: DIALOGS.Login})
        reportSmBi(events.SITE_MEMBER_SUBMIT_BUTTON, {context: notificationId})
    })
}
