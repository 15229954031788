import * as constants from '../../santa-renderer/src/utils/constants'

const {PARENT, META_DATA, DATA_QUERY, PROPERTY_QUERY, DESIGN_QUERY, LAYOUT, SKIN, STYLE_ID, BEHAVIOR_QUERY, CONNECTION_QUERY, ID, TYPE, COMPONENT_TYPE} = constants.pointers.components.PROPERTY_TYPES
const {DATA, DESIGN, PROPERTIES, STYLE, BEHAVIORS, CONNECTIONS} = constants.pointers.data.DATA_MAPS

export interface Docking {
    left, right, top, bottom: {
        margin?: Partial<{
            vw: number
            px: number
        }>
    }
}
export interface Layout {
    x, y, width, height: number
    rotationInDegrees: number
    fixedPosition: boolean
    scale: number
    docked: Partial<Docking>
}

export interface ComponentMetaData {
    isPreset: boolean,
    pageId: string
}

export interface Style {
    skin: string
    style: {
        properties: {
            [id: string]: string
        }
    }
}

export interface BehaviorItem {

}

export enum ConnectionType {
    WixCodeConnectionItem = 'WixCodeConnectionItem',
    ConnectionItem = 'ConnectionItem'
}

interface ControllerConnectionItem {
    type: ConnectionType.ConnectionItem,
    role: string,
    controllerId: string,
    config?: any,
    isPrimary: boolean
}

interface WixCodeConnectionItem {
    type: ConnectionType.WixCodeConnectionItem,
    role: string
}

export type ConnectionItem = ControllerConnectionItem | WixCodeConnectionItem

export interface ConnectionList {
    id: string,
    type: string,
    items: ConnectionItem[]
}

export interface DataMap {
    [STYLE]: {
        THEME_DATA: {
            font: string
            color: string
        }
    } & {[styleId: string]: Style}

    [DESIGN]: {}
    [DATA]: {}
    [PROPERTIES]: {}
    [CONNECTIONS]: {[connectionId: string]: ConnectionList}
    mobile_hints: {}

    [BEHAVIORS]: {
        [behaviorId: string]: {
            items: string
        }
    }
}

export type PropertyType = 'designQuery' | 'propertyQuery' | 'dataQuery' | 'connectionQuery' | 'behaviorQuery'
export type Constants = typeof constants
export interface Component {
    [ID]: string
    [LAYOUT]: Partial<Layout>
    [TYPE]: string
    [COMPONENT_TYPE]: string
    [DATA_QUERY]: string
    [PROPERTY_QUERY]: string
    [CONNECTION_QUERY]: string
    [BEHAVIOR_QUERY]: string
    [DESIGN_QUERY]: string
    components: string[]
    [PARENT]: string
    [META_DATA]: Partial<ComponentMetaData>
    [STYLE_ID]: string
    [SKIN]: string
}

export interface Structure {
    [id: string]: Partial<Component>
}
