import _ from 'lodash'
import {withActions} from 'carmi-host-extensions'

export const name = 'NavigationBaseAspect'

export const defaultModel = {
    dyingPrimaryPageInfo: null,
    isduringTransition: false,
    nextNavigationInfoForSM: null,
    prevPages: [],
    renderedPages: []
}

export const functionLibrary = {
    startNavigationProcess: withActions((actions, currentNavInfos, setToHostInstance, isAllowedBySM, getPrevState, navInfo) => {
        const isDynamicPageRouting = !navInfo.pageId && navInfo.routerDefinition
        if (navInfo.isPopup || isAllowedBySM(navInfo.pageId) || isDynamicPageRouting) {
            actions.setNextNavigationForSM(null)

            const prevState = getPrevState()
            const currentPrevPages = prevState.prevPages
            if (!_.includes(currentPrevPages, prevState.primaryPageId)) {
                actions.savePageInPrevPages(currentPrevPages.length, 0, prevState.primaryPageId)
            }

            let nextNavInfos = navInfo.isPopup ?
                _.assign({}, currentNavInfos, {popupPage: navInfo}) :
                {primaryPage: navInfo}

            if (navInfo.isKeepingRoots) {
                nextNavInfos = _.assign({}, nextNavInfos, {popupPage: navInfo})
            }

            setToHostInstance(_.cloneDeep(nextNavInfos))
        } else {
            actions.setNextNavigationForSM(navInfo)
        }
    }),

    actualNavigate: withActions((actions, currentNavInfos, nextNavInfos) => {
        actions.setNextNavigationForSM(null)
        actions.setRootNavigationInfos(nextNavInfos)

        if (nextNavInfos.primaryPage.pageId !== currentNavInfos.primaryPage.pageId) {
            actions.setDyingPrimaryPageInfo(currentNavInfos.primaryPage)
            actions.setIsDuringTransition(true)
        }
    }),

    endTransition: withActions((actions, dyingPageRef) => {
        if (dyingPageRef) {
            dyingPageRef.refs[''].style.visibility = 'hidden'
            dyingPageRef.refs[''].style.maxHeight = '0px'
            dyingPageRef.refs[''].style.overflow = 'hidden'
        }

        actions.setDyingPrimaryPageInfo(null)
        actions.setIsDuringTransition(false)
    }),

    addPageToRenderedPages: withActions((actions, dataRequirementsState, pageId, getCurrentRenderedPages) => {
        if (!dataRequirementsState) {
            return
        }
        const currentRenderedPages = getCurrentRenderedPages()
        if (!_.includes(currentRenderedPages, pageId)) {
            actions.savePageInRenderedPages(currentRenderedPages.length, 0, pageId)
        }
    })
}
