import {layoutUtils} from 'santa-core-utils'

export const name = 'ComponentStyleAspect'

export const functionLibrary = {
    getBaseStyle: (layout, screenLayout, layoutMechanism) => {
        const getScreenLayoutFunc = () => screenLayout
        return layoutUtils.getStyle(layout, getScreenLayoutFunc, layoutMechanism)
    }
}
