import _ from 'lodash'
import {fonts} from 'santa-core-utils'
import color from 'color'

const castToNumber = value => Number(value)

const regExpMatch = (toFind, regExpStr) => toFind.match(new RegExp(regExpStr))

const formatColor = (value, alpha) => {
    const c = color(value)
    c.alpha(alpha)
    return c.rgbaString().replace(/ /g, '')
}

const rgbToHex = rgbAsString => {
    const rgbAsArray = rgbAsString.split(',')

    return color({
        r: rgbAsArray[0],
        g: rgbAsArray[1],
        b: rgbAsArray[2]
    }).hexString()
}

export const tpaStyleServicesFunctionLibrary = {
    doubleQuotesEscapeForIE: str => str.replace(/''/g, "'"),
    mathFloor: Math.floor,
    parseFontStr: fonts.parseFontStr,
    getFontFamilyWithFallbacks: fonts.getFontFamilyWithFallbacks,
    formatColor,
    castToNumber,
    regExpMatch,
    isString: _.isString,
    rgbToHex

}
