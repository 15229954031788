export const name = 'toDoAspect'

export const defaultModel = {
    nextPageToRender: null,
    isZoomOpened: false,
    biData: {
        time: {
            loadingTime: 0,
            totalLoadingTime: 0
        },
        pageId: 'pageId',
        pageNumber: 0
    },
    tpaHeadTags: {},
    dynamicPageHeadTags: null
}

export const functionLibrary = {
    getTime: () => ({
        loadingTime: 10,
        totalLoadingTime: 100
    })
}

// //if adding init, need to export initMethodData in .carmi file
// export function init() {
//
// }
